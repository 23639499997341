/* reset */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ”;
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: inherit;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  cursor: pointer;
  border: 0;
}

button:focus {
  outline: 0;
}

li,
ul {
  list-style: none;
}

textarea:focus,
input:focus {
  outline: none;
}

textarea {
  resize: none;
}

input,
textarea {
}

// my custom base setup
//base setup
html,
body {
  // min-height: 100% !important;
  // min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#root,
.App {
  // min-height: 100%;
}

em {
  font-style: normal;
}

@media only screen and (min-width: 1024px) and (max-width: 1419px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (min-width: 564px) and (max-width: 767px) {
}

@media only screen and (max-width: 563px) {
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sign-out-text {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  a {
    font-weight: 400;
    font-size: 0.9rem;
    text-decoration: underline;
    color: #aaa;
  }
}