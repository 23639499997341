.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .slick__container {
    display: block;
    width: 100%;

    .slick-prev {
      left: 20px;
      z-index: 100;

      &::before {
        display: none;
      }
    }
    .slick-dots li.slick-active button:before {
      color: #fff;
    }
    .slick-dots li button:before {
      color: #fff;
      font-size: 20px;
    }
    .slick-next {
      right: 20px;
      &::before {
        display: none;
      }
    }
    .slick-dots {
      bottom: 60px;
    }
  }
  .slick__item {
    width: 100%;
    height: 780px;
    position: relative;
    display: flex !important;
    justify-content: center;

    .bg__box {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 780px;
      overflow: hidden;
      img {
        z-index: 10;
        width: 100vw;
        height: 100%;
      }
    }
    .item__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      min-width: $wrapper-min-width;
      max-width: $wrapper-min-width;
      .title__box {
        display: flex;
        z-index: 100;
        h1 {
          z-index: 100;
          color: #fff;
          margin-right: 16px;
          margin-bottom: 30px;
          font-weight: normal;
        }
        h4 {
          z-index: 100;
        }
      }
      .sub-title__box {
        display: flex;
        z-index: 100;
        margin-bottom: 240px;
        h4 {
          z-index: 100;
          color: #fff;
          font-weight: normal;
          white-space: pre-wrap;
          line-height: 1.5;
        }
      }
      .button__box {
        z-index: 100;
        display: flex;
        border-radius: 32.5px;
        background: #fff;
        width: 240px;
        height: 56px;
        margin-bottom: 200px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span {
          z-index: 100;
          font-size: 18px;
        }
      }
    }
  }
  .project__container {
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    width: 100%;
    & > header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 36px;
      .link {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .project__list {
      display: flex;
      margin-bottom: 160px;

      .project__item {
        flex: 1;
        max-width: 320px;
        border-radius: 24px;
        border: solid 1px $--gray-200;
        margin-right: 89px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
        .img__box {
          display: flex;
          height: 262px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title__box {
          margin-top: 20px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          span {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
          }
        }
        .area__box {
          margin-top: 8px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          span {
            font-size: 14px;
            color: #aaaaaa;
          }
        }
        .price__box {
          margin-top: 12px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 20px;
        }
      }
    }
    .m-button__box {
      display: none;
    }
  }
  .news__container {
    display: flex;
    flex-direction: column;
    margin-top: 160px;
    width: 100%;
    & > header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 36px;
      .link {
        display: flex;
        align-items: center;
      }
    }
    .news__list {
      display: flex;
      .news__item {
        flex: 1;
        max-width: 320px;
        border-radius: 24px;
        border: solid 1px $--gray-200;
        margin-right: 89px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }
        .img__box {
          display: flex;
          height: 262px;
          img {
            width: 100%;
          }
        }
        .title__box {
          margin-top: 20px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          span {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
          }
        }
        .sub-title__box {
          margin-top: 8px;
          padding: 0px 20px;
          box-sizing: border-box;
          display: flex;
          margin-bottom: 20px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .full__wrapper {
    width: 100%;
    display: flex;
    background: #f7f7f9;
    justify-content: center;
    &.news {
      padding-bottom: 160px;
    }
    &.qna {
      padding-top: 120px;
      padding-bottom: 80px;
    }
  }

  .contributors__container {
    display: flex;
    width: 100%;
    padding-top: 140px;
    flex-direction: column;
    margin-bottom: 100px;
    & > header {
      h1 {
        margin-bottom: 12px;
        font-size: 40px;
      }
      h2 {
        font-weight: normal;
      }
    }
    .contributors__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-top: 78px;

      .contributors__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        .img__box {
          display: flex;
          width: 160px;
          height: 160px;
          border-radius: 50%;
          overflow: hidden;
          background: #eeeeee;
          img {
            width: 100%;
          }
        }
        .name__box {
          display: flex;
          margin-top: 20px;
        }
      }
    }
  }

  .culture__container {
    width: 100%;
    margin: 100px 0;
    img {
      display: block;
      margin: 0 auto;
      width: 1136px;
    }
  }

  .pople__container-top {
    .text__title {
      margin-top: 116px;
      font-size: 42px;
      text-align: center;
      font-weight: 800;
      color: #000000;
    }
    img {
      display: block;
      width: 70px;
      height: 25px;
      margin: 80px auto 48px;
    }
    .text__explain {
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      color: #1a1a1a;
    }
    .text__explain_mobile {
      display: none;
    }
  }
  .pople__container {
    display: flex;
    width: 100%;
    padding-top: 60px;
    position: relative;
    height: 360px;
    .text__box {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      padding-top: 44px;

      h1 {
        color: #5d3be5;
        font-size: 40px;
        margin-bottom: 12px;
      }
      h2 {
        font-weight: normal;
      }
      .button {
        margin-top: 40px;
        width: 240px;
        border-radius: 32.5px;
        background-color: $--main-500;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }
    }

    // .m-illust__box {
    //   display: none;
    // }
  }

  .platform__container {
    background-color: #f2efff;
    width: 100%;
    padding-top: 129px;
    position: relative;
    height: 721px;

    .platform__title {
      color: #000000;
      font-weight: 700;
      font-size: 23px;
      text-align: center;
    }
    .platform__lists {
      display: flex;
      justify-content: center;
      margin-top: 98px;
    }
    .platform__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 235px;
      height: 370px;
      margin: 0 38px;
    }
    .platform__summary {
      text-align: center;
      color: #5d3be5;
      font-size: 18px;
      line-height: 18px;
      margin-top: 36px;
    }
    .platform__text {
      text-align: center;
      font-size: 14px;
      line-height: 25px;
      color: #1a1a1a;
      margin-top: 7px;
    }
    .platform__image_outer {
      height: 120px;
    }
    .platform__image1 {
      width: 76px;
      height: 104px;
    }
    .platform__image2 {
      width: 163px;
      height: 115px;
    }
    .platform__image3 {
      width: 159px;
      height: 117px;
    }
    .button {
      cursor: pointer;
      margin: 40px 16px;
      width: 240px;
      border-radius: 32.5px;
      background-color: $--main-500;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
    .button__blank {
      cursor: pointer;
      margin: 40px 16px;
      width: 240px;
      border-radius: 32.5px;
      border: 1px solid #5d3be5;
      background-color: #ffffff;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #5d3be5;
      font-size: 18px;
      cursor: pointer;
    }
    .m-button__box {
      display: none;
    }
    .platform__buttons-pc {
      display: flex;
      justify-content: center;
    }
  }
  .qna__container {
    display: flex;
    .qna__tiem {
      display: flex;
      cursor: pointer;
      width: 560px;
      height: 281px;

      &.first {
        margin-right: 16px;
      }
      &.second {
      }
    }
  }
}

@media only screen and (max-width: 1136px) {
  .home-page {
    .slick__container {
      .slick-dots {
        bottom: 23px;
        li {
          width: 12px;
          height: 12px;
          button {
            &:before {
              font-size: 12px;
            }
          }
        }
      }
    }
    .slick__item {
      height: 500px;
      .item__wrapper {
        min-width: 100%;
        max-width: 100%;
        align-items: center;
        padding-bottom: 80px;

        .title__box {
          flex-direction: column;
          align-items: center;

          h1 {
            font-size: 24px;
            margin-right: 0px;
            margin-bottom: 12px;
          }
        }
        .sub-title__box {
          margin-top: 24px;
          padding: 0px 16px;
          box-sizing: border-box;
          margin-bottom: 24px;

          h4 {
            font-size: 16px;
            text-align: center;
          }
        }
        .button__box {
          display: none;
        }
      }
      .bg__box {
        height: 500px;
        width: 100%;
        img {
        }
      }
    }

    .slick-arrow {
      top: auto;
      bottom: 12px;
      z-index: 10;
      .slick-next {
        width: 36px;
        height: 36px;
        right: 0px;
      }
      .slick-prev {
        width: 36px;
        height: 36px;
        left: 0px;
      }
    }

    .project__container {
      padding: 0px 16px;
      box-sizing: border-box;
      margin-top: 36px;
      & > header {
        justify-content: center;
        h1 {
          font-size: 24px;
        }
        .link {
          display: none;
        }
      }
      .project__list {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
        .project__item {
          margin-right: 0px;
          margin-bottom: 36px;
          width: 100%;
          max-width: $mobile-content-max-width;
        }
      }
      .m-button__box {
        display: flex;
        justify-content: center;
        margin-bottom: 56px;

        .button {
          cursor: pointer;
          width: 100%;
          max-width: $mobile-content-max-width;
          background: #eeeeee;
          height: 50px;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
        }
      }
    }

    .m-contributors__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      & > header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 16px;
        box-sizing: border-box;
        h1 {
          font-size: 24px;
          margin-bottom: 8px;
        }
        h2 {
          font-size: 16px;
          font-weight: normal;
          text-align: center;
        }
      }

      .slick__container {
        margin-top: 56px;
        display: block;
        width: 100%;
        max-width: $mobile-content-max-width;
        padding-bottom: 100px;
        .contributors__wrapper {
          width: 100%;
          .contributors-list__item {
            width: 100%;
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            .contributors__item {
              width: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 36px;
              .img__box {
                width: 128px;
                height: 128px;
                min-width: 128px;
                min-height: 128px;
                border-radius: 50%;
                overflow: hidden;
                background: #eeeeee;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .name__box {
                margin-top: 16px;
              }
            }
          }
        }

        .slick-dots li.slick-active button:before {
          color: #5d3be5;
        }
        .slick-dots li button:before {
          color: #777777;
          font-size: 12px;
          opacity: 1;
        }
        .slick-next {
          right: 20px;
          &::before {
            display: none;
          }
        }
        .slick-dots {
          bottom: -20px;
        }
      }
    }

    .culture__container {
      width: 100%;
      img {
        width: 400px;
      }
    }

    .pople__container-top {
      .text__title {
        margin-top: 60px;
        font-size: 25px;
        text-align: center;
        font-weight: 800;
        color: #000000;
      }
      img {
        display: block;
        width: 70px;
        height: 25px;
        margin: 47px auto 20px;
      }
      .text__explain {
        display: none;
      }
      .text__explain_mobile {
        display: block;
        margin: 0 36px;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        color: #1a1a1a;
      }
    }
    .pople__container {
      flex-direction: column-reverse;
      align-items: center;
      height: auto;
      padding-top: 36px;

      .text__box {
        align-items: center;
        margin-left: 0px;
        margin-bottom: 46px;
        h1 {
          font-size: 24px;
        }
        h2 {
          font-size: 16px;
        }
        .button {
          margin-top: 20px;
        }
      }
      .illust__box-1 {
        width: 162px;
        height: 240px;
      }
      .illust__box-2 {
        width: 215px;
        height: 240px;
      }
      // .m-illust__box {
      //   cursor: pointer;
      //   display: flex;
      //   width: 100%;
      //   max-width: 375px;
      //   height: auto;
      //   img {
      //     width: 100%;
      //   }
      // }
    }
    .platform__container {
      padding-top: 60px;
      height: 1465px;
      .platform__title {
        margin-bottom: 32px;
      }
      .m-button__box {
        display: flex;
        justify-content: center;

        .button {
          cursor: pointer;
          margin: 8px auto;
          width: 240px;
          border-radius: 32.5px;
          background-color: $--main-500;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
        .button__blank {
          cursor: pointer;
          margin: 8px auto;
          width: 240px;
          border-radius: 32.5px;
          border: 1px solid #5d3be5;
          background-color: #ffffff;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #5d3be5;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .border__bottom {
        border-bottom: 1px solid #5d3be5;
      }
      .platform__lists {
        display: block;
        justify-content: center;
        margin-top: 16px;
      }
      .platform__list {
        margin: 48px auto 16px;
      }
      .button__blank {
        margin: 40px auto;
      }
      .platform__buttons-mobile {
        display: block;
      }
      .platform__buttons-pc {
        display: none;
      }
    }
    .qna__container {
      flex-direction: column;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
      align-items: center;

      .qna__tiem {
        max-width: 375px;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          object-fit: contain;
        }
        &.first {
          margin-right: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
