$primary-color: #03a1c8;
$second-color: #f3924b;
$error-color: #e50914;
$contrast-color: #fff;

// content
$text-color: #999;
$title-color: #333;
$placeholder-color: #b8b8b8;
$border-color: #ced4da;
$base-bg-color: #fff;

//box-shadow
$box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
$box-shadow-hover: rgba(56, 72, 157, 0.5) 0px 3px 8px;

//pople color
$--main-500: #5d3be5;
$--main-300: #ded6ff;
$--main-100: #faf9ff;
$--red-500: #f41515;
$--gray-900: #161616;
$--gray-600: #555b67;
$--grey-500: #777;
$--gray-400: #aaa;
$--gray-350: #bcbcbc;
$--gray-300: #ccc;
$--gray-200: #eee;
$--gray-100: #f7f7f9;
$--gray-0: #fff;

$board-bg-color: #f6f6f6;
$board-border-color: #e3e3e3;

$--mono-000-light: #222;
$--mono-100-light: #8a8a8e;
$--mono-200-light: #bfbfc1;
$--mono-300-light: #dcdcdd;
$--mono-000-dark: #fff;
$--mono-100-dark: #f3f3f9;
$--mono-200-dark: #f9f9fc;
$--mono-300-dark: #fbfbfd;

$--fill-000-light: #e9e9eb;
$--fill-100-light: #efeff0;
$--fill-200-light: #f4f4f5;
$--fill-300-light: #f9f9fa;
$--fill-000-dark: #bbbbbf;
$--fill-100-dark: #d3d3d6;
$--fill-200-dark: #dedee0;
$--fill-300-dark: #e9e9eb;
