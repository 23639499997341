.login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .login__container {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
    }

    .input__box {
      width: 100%;
      display: flex;
      flex-direction: column;

      & > div {
      }
    }

    .button__box {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > button {
      }
    }
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
  .login-page {
    .login__container {
      width: 100%;
    }
  }
}
