.basic-tab {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid 1px $--gray-200;

  & > ul {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 2px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 40px;
      // background-color: $--gray-100;
      color: $--gray-600;
      span {
        font-size: 18px;
      }

      &.active {
        background: $--main-100;
        border-bottom: 2px solid $--main-500;
        span {
          color: $--main-500;
          font-weight: 600;
        }
      }
    }
  }
}
