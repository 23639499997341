.content-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .section__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $--gray-200;
    align-items: center;
    padding-bottom: 40px;
    margin-top: 40px;

    & > header {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: solid 2px $--gray-600;
      margin-bottom: 40px;
    }
  }

  .form-list {
    width: 944px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 104px;
  }

  .caption__box {
    display: flex;
    grid-column: 1/3;

    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        align-content: center;
        height: 40px;

        & > header {
          display: flex;
          min-width: 160px;
          span {
            color: $--grey-500;
          }
        }

        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-600;
        }
      }
    }
  }

  .terms--list {
    width: 944px;
    display: flex;
    flex-direction: column;

    & > header {
      display: flex;
      align-content: center;
      margin-bottom: 20px;
    }

    li {
      //mui custom

      .title__box {
        width: 100%;

        display: flex;
        justify-content: center;
        align-content: center;
        span {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }

      .content__box {
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
      }

      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }

      .MuiAccordion-root {
        border-radius: 8px;
        background-color: #f6f6f6;
        box-shadow: none;
        &.Mui-expanded {
          margin: 0px;
        }
        &.top {
          .MuiAccordionSummary-root {
          }
        }
      }

      .MuiAccordionSummary-root {
        &.Mui-expanded {
          min-height: 48px;
        }
      }
      .MuiAccordionSummary-content {
        overflow: hidden;
        padding: 0px;
        margin: 0px;
      }
    }
  }

  .download__box {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f6f6f6;
    box-sizing: border-box;
    width: 100%;
    margin-top: 40px;
    width: 944px;

    & > header {
      display: flex;
      flex-direction: column;
      flex: 1;
      b {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 10px;
      }

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    button {
      font-size: 14px;
      font-weight: bold;
      background: transparent;
    }
  }

  .evidence-list {
    display: flex;
    width: 944px;
  }

  .co2__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $--gray-200;
    align-items: center;
    margin-top: 40px;

    & > header {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: solid 2px $--gray-600;
    }

    .co2__box {
      display: flex;
      width: 100%;

      .left {
        & > header {
          width: 100%;
          margin-top: 40px;
          margin-bottom: 34px;
        }
        padding: 0px 50px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .right {
        & > header {
          width: 100%;
          margin-top: 40px;
          margin-bottom: 34px;
        }
        flex: 1;
        padding: 0px 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #f6f6f6;
      }
    }
  }
}
