//default
@import './tailwind.scss';
@import './default.scss';
@import './variables.scss';
@import './color.scss';
@import './font.scss';
@import './ico.scss';
//content
@import './components/index.scss';
@import './layouts/index.scss';
@import './pages/index.scss';
@import './layer/index.scss';

body {
  padding-right: 0 !important;
}
div,
section,
article {
  box-sizing: border-box;
}
// ::selection {
//   background-color: $--main-500;
//   color: #fff;
// }

p {
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 1.5;
}

.text-white {
  color: #fff;
}

.low-number {
  font-size: 8px;
}

.low-number-big {
  font-size: 12px;
}

.margin-left-null {
  margin-left: 0px !important;
}
