.ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: transparent;
  cursor: pointer;

  &.nav-logo-purple {
    background-image: url('./icon/logo_pople.svg');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.nav-logo-white {
    background-image: url('./icon/logo_pople_white.svg');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.logo {
    background-image: url('./icon/logo@3x.png');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.logout {
    background-image: url('./icon/icon_logout.svg');
    width: 18px;
    height: 18px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.outer {
    background-image: url('./icon/icon_outer_link.svg');
    width: 15px;
    height: 15px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.outer_black {
    background-image: url('./icon/icon_outer_link_black.svg');
    width: 15px;
    height: 15px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.main-logo-white {
    background-image: url('./icon/logo-white@3x.png');
    width: 144px;
    height: 55px;
    background-position: center;
    background-size: cover;
  }
  &.logo-white {
    background-image: url('./icon/logo-white@3x.png');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.mypage {
    background-image: url('./icon/icon_user.svg');
    width: 28px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.logo-small {
    background-image: url('./icon/logo@3x.png');
    width: 62px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }

  &.phone {
    background-image: url('./icon/phone@3x.png');
    width: 35px;
    height: 35px;
    background-position: center;
    background-size: cover;
  }
  &.check {
    background-image: url('./icon/check-off@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
    &.on {
      background-image: url('./icon/check-on@3x.png');
    }
  }
  &.required {
    background-image: url('./icon/required@3x.png');
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: cover;
  }
  &.circle-check {
    background-image: url('./icon/icon_circle_check.svg');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.eye {
    background-image: url('./icon/eye@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.eye-off {
    background-image: url('./icon/eye-off@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }

  &.close {
    background-image: url('./icon/close@3x.png');
    width: 36px;
    height: 36px;
    background-position: center;
    background-size: cover;
  }
  &.button_close {
    background-image: url('./icon/button_close.svg');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.close-small {
    background-image: url('./icon/close@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.next {
    background-image: url('./icon/next@3x.png');
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: cover;
  }
  &.next-white {
    background-image: url('./icon/01-icon-next-white@3x.png');
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: cover;
  }
  &.big-next {
    background-image: url('./icon/01-icon-big-next@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.arrow-down {
    background-image: url('./icon/arrow-down@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.white-arrow-down {
    background-image: url('./icon/white_arrow_down.svg');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.check-square-on {
    background-image: url('./icon/check-square-on@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.check-square {
    background-image: url('./icon/check-square-off@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.tri {
    background-image: url('./icon/tri@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.store {
    background-image: url('./icon/icon-store@3x.png');
    width: 20px;
    height: 17px;
    background-position: center;
    background-size: cover;
  }
  &.forward {
    background-image: url('./icon/button-foward@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.backward {
    background-image: url('./icon/button-backward@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-1 {
    background-image: url('./icon/home-illust-1@3x.png');
    width: 204px;
    height: 136px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-2 {
    background-image: url('./icon/home-illust-2@3x.png');
    width: 204px;
    height: 136px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-3 {
    background-image: url('./icon/home-illust-3@3x.png');
    width: 204px;
    height: 136px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-4 {
    background-image: url('./icon/home-illust-4@3x.png');
    width: 204px;
    height: 136px;
    background-position: center;
    background-size: cover;
  }
  &.certification-1 {
    background-image: url('./icon/certification-1@3x.png');
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
  }
  &.certification-2 {
    background-image: url('./icon/certification-2@3x.png');
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
  }
  &.certification-3 {
    background-image: url('./icon/certification-3@3x.png');
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
  }
  &.certification-4 {
    background-image: url('./icon/certification-4@3x.png');
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
  }
  &.certification-5 {
    background-image: url('./icon/certification-5@3x.png');
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-1 {
    background-image: url('./icon/home-icon-1@3x.png');
    width: 48px;
    height: 56px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-2 {
    background-image: url('./icon/home-icon-2@3x.png');
    width: 48px;
    height: 56px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-3 {
    background-image: url('./icon/home-icon-3@3x.png');
    width: 48px;
    height: 56px;
    background-position: center;
    background-size: cover;
  }
  &.small-next {
    background-image: url('./icon/01-icon-next@3x.png');
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: cover;
  }
  &.small-email {
    background-image: url('./icon/01-icon-email@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.small-email-2 {
    background-image: url('./icon/01-icon-email-2@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.slick-next {
    background-image: url('./icon/01-icon-slick-next@3x.png');
    width: 56px;
    height: 56px;
    background-position: center;
    background-size: cover;
  }
  &.slick-prev {
    background-image: url('./icon/01-icon-slick-prev@3x.png');
    width: 56px;
    height: 56px;
    background-position: center;
    background-size: cover;
  }
  &.type-01 {
    background-image: url('./icon/02-icon-type-01@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-02 {
    background-image: url('./icon/02-icon-type-02@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-03 {
    background-image: url('./icon/02-icon-type-03@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-04 {
    background-image: url('./icon/02-icon-type-04@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-05 {
    background-image: url('./icon/02-icon-type-05@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-06 {
    background-image: url('./icon/02-icon-type-06@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-07 {
    background-image: url('./icon/02-icon-type-07@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-08 {
    background-image: url('./icon/02-icon-type-08@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-09 {
    background-image: url('./icon/02-icon-type-09@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-10 {
    background-image: url('./icon/02-icon-type-10@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-11 {
    background-image: url('./icon/02-icon-type-11@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-12 {
    background-image: url('./icon/02-icon-type-12@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-13 {
    background-image: url('./icon/02-icon-type-13@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.type-14 {
    background-image: url('./icon/02-icon-type-14@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.complete-check {
    background-image: url('./icon/01-icon-check@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.info {
    background-image: url('./icon/01-icon-info@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.verywords {
    background-image: url('./icon/verywords.png');
    width: 210px;
    height: 43px;
    background-position: center;
    background-size: cover;
  }
  &.gift {
    background-image: url('./icon/01-icon-gift@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.small-kakao {
    background-image: url('./icon/01-icon-kakao@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.small-google {
    background-image: url('./icon/01-icon-google@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.cert-img {
    background-image: url('./icon/group-8@3x.png');
    width: 108px;
    height: 108px;
    background-position: center;
    background-size: cover;
  }
  // &.cert-img {
  //   background-image: url('./icon/cert_bitmap@3x.png');
  //   width: 90px;
  //   height: 66px;
  //   background-position: center;
  //   background-size: cover;
  // }
  &.small-cert-img {
    background-image: url('./icon/contributor-img@3x.png');
    width: 52px;
    height: 52px;
    background-position: center;
    background-size: cover;
  }
  &.big-cert-img {
    background-image: url('./icon/contributor-img@3x.png');
    width: 108px;
    height: 108px;
    background-position: center;
    background-size: cover;
  }
  &.header-menu {
    background-image: url('./icon/01-icon-menu@3x.png');
    width: 36px;
    height: 36px;
    background-position: center;
    background-size: cover;
  }
  &.chevron-down {
    background-image: url('./icon/01-icon-chevron-down@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.no-data {
    background-image: url('./icon/icon_nodata.svg');
    width: 50px;
    height: 50px;
    opacity: 0.7;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
}
