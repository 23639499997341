.basic-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // &.basic-page-mt {
  //   margin-top: 88px;
  // }

  .list__box > ul .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 20px 0;
    height: 100px;
    span {
      padding: 10px;
      color: $--gray-600;
    }
  }

  &.basic-page-mt {
    padding-top: 88px;
  }
  .brand__logo {
    margin-top: 14px;
    img {
      display: block;
      margin: 0 auto;
      width: 1136px;
    }
  }

  //m-form container
  .m-headding-text__container {
    display: none;
  }

  //인증에서 가져옴
  //general
  .button__container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

    &.right {
      justify-content: right;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.column {
      flex-direction: column;
      align-items: center;
    }

    .prev__box {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
    }
  }

  .border-grey-200 {
    height: 1px;
    display: flex;
    width: 100%;
    background: $--gray-200;
  }

  //form 용

  .form__container {
    display: flex;
    flex-direction: column;
    width: 944px;
    padding: 40px 0px;

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px 104px;

      &.board {
        border-top: solid 1px $--gray-600;
        gap: 0px;
      }
    }
  }

  // methodology
  .guide-text__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px $--gray-200;

    h1 {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;
    }

    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;
    }
  }

  //complete용
  .complete-guide__container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 20px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;

      em {
        color: $--main-500;
      }
    }

    .img__box {
      margin-top: 36px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;

      .illust {
        display: flex;
        width: 320px;
        height: 240px;
        background: url('../images/illust@3x.png');
        background-size: cover;
      }
    }
  }

  .complete-phone__container {
    padding: 30px 0px;
    display: flex;
    border-top: solid 1px #e3e3e3;
    border-bottom: solid 1px #e3e3e3;
    width: 378px;

    .img__box {
      margin-right: 20px;
    }

    .text__box {
      display: flex;
      flex-direction: column;

      h2 {
        margin-top: 5px;
        margin-bottom: 20px;
      }

      span {
        font-size: 16px;
        line-height: 1.5;
      }

      em {
        line-height: 1.5;
        color: $--grey-500;
      }
    }
  }

  .guide-heading__container {
    margin-top: 40px;

    em {
      color: $--main-500;
    }
  }

  //user page
  //user-signup
  .headding-text__container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;

    & > header {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: solid 1px $--gray-200;

      &.custom {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .breadcrumb__box {
        display: flex;
        align-items: center;

        ul {
          display: flex;

          li {
            &::after {
              content: '>';
              margin: 0px 10px;
              color: #000000;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }

            cursor: pointer;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;

            &.active {
              font-weight: bold;
            }
          }
        }
      }
    }

    .right__box {
      display: flex;
      position: absolute;
      right: 0px;
    }
  }

  .singup-select__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 80px;

    .m-tab__box {
      display: none;
    }

    & > ul {
      width: 100%;
      display: flex;
      justify-content: center;

      .border-line {
        width: 1px;
        height: 291px;
        background-color: $--gray-200;
        margin: 0px 60px;
      }

      li {
        flex: 1;
        max-width: $mobile-content-max-width;
        display: flex;
        flex-direction: column;

        & > header {
          display: flex;
          align-items: center;
          margin-bottom: 13px;

          span {
            font-size: 14px;
          }
        }

        .illust__box {
          display: flex;
          justify-content: center;
          margin-bottom: 21px;

          .illust-personal {
            width: 138px;
            height: 138px;
            background: url('../icon/icon_person.svg');
            background-size: 100% 100%;
          }

          .illust-company {
            width: 138px;
            height: 138px;
            background: url('../icon/icon_company.svg');
            background-size: 100% 100%;
          }
        }

        .sns__box {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;

          & > header {
            font-size: 14px;
            margin-bottom: 16px;
          }

          .button__box {
            display: flex;
            width: 100%;

            .kakao {
              cursor: pointer;
              flex: 1;
              display: flex;
              border-radius: 25px;
              background-color: #fee500;
              height: 50px;
              margin-right: 12px;
              justify-content: center;
              align-items: center;

              .ico {
                margin-right: 8px;
              }

              span {
                font-size: 14px;
              }
            }

            .google {
              cursor: pointer;
              flex: 1;
              display: flex;
              flex-direction: row;
              border-radius: 25px;
              border: solid 1px #cccccc;
              height: 50px;
              justify-content: center;
              align-items: center;

              .ico {
                margin-right: 8px;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }

        .signup-button {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 25px;
          background-color: $--main-500;
          height: 50px;
          color: #fff;

          .ico {
            margin-right: 8px;
          }

          &.grey {
            background-color: #f7f7f7;
            color: #aaaaaa;
          }
        }
      }
    }
  }

  // signUp,login용
  .user-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 560px;

    & > header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: solid 1px $--grey-500;

      em {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--red-500;
      }
    }

    //terms

    .terms__list {
      display: flex;
      flex-direction: column;

      li {
        margin-top: 32px;
        display: flex;
        flex-direction: column;

        & > header {
          margin-bottom: 12px;
        }

        .text__viewer {
          height: 220px;
          overflow-y: auto;
          display: flex;
          padding: 12px;
          box-sizing: border-box;
          border: solid 1px $--gray-300;
          background-color: $--gray-0;

          p {
            white-space: pre-line;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $--grey-500;
          }
        }
      }
    }

    .form__list {
      display: flex;
      flex-direction: column;

      & > article {
        margin-top: 32px;
      }
    }
  }

  .user-stepper__container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
  }

  .user-email__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 560px;

    .mail__box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        display: flex;
        width: 128px;
        height: 128px;
        background: url('../images/email@3x.png');
        background-size: cover;
      }
    }

    .value__box {
      margin-top: 36px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $--gray-100;

      h2 {
        color: $--main-500;
      }
    }

    .guide__box {
      margin-top: 64px;
      display: flex;
      justify-content: center;
    }

    .info__box {
      margin-top: 36px;
      background-color: $--gray-100;
      display: flex;
      align-items: center;
      height: 90px;
      padding: 0px 20px;
      box-sizing: border-box;

      & > ul {
        display: flex;
        flex-direction: column;

        li {
          line-height: 1.5;
          letter-spacing: normal;
          color: $--gray-900;
          font-size: 16px;

          em {
            color: $--main-500;
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }
  }

  .login-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 360px;
    margin-bottom: 300px;

    .option__box {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;

      & > ul {
        display: flex;

        li {
          display: flex;
          align-items: center;

          &::after {
            display: flex;
            content: '';
            height: 20px;
            width: 1px;
            background: $--gray-200;
            margin-left: 8px;
            margin-right: 8px;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--gray-900;

            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }

    .guide__text {
      display: flex;
      margin-bottom: 28px;

      span {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;

        em {
          font-weight: bold;
        }
      }
    }

    .sns__box {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      ul {
        display: flex;
        width: 100%;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          margin-right: 12px;
          cursor: pointer;

          span {
            font-size: 14px;
          }

          &.kakao {
            border-radius: 25px;
            height: 50px;
            background: #fee500;

            .ico {
              margin-right: 8px;
            }
          }

          &.google {
            border-radius: 25px;
            height: 50px;
            border: solid 1px #cccccc;

            .ico {
              margin-right: 8px;
            }
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }

  //signout용
  .user-signout-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .grid__box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }

    .form__list {
      display: flex;
      flex-direction: column;

      .guide__box {
        margin-top: 0px;

        h2 {
          margin-bottom: 16px;
        }

        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--grey-500;
        }
      }

      & > article {
        margin-top: 32px;
      }
    }

    .info__box {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 24px;
      box-sizing: border-box;
      background-color: $--gray-100;

      & > ul {
        margin-top: 12px;
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $--grey-500;

          &::before {
            content: '·';
            margin-right: 3px;
            display: flex;
            font-size: 14px;
          }
        }
      }
    }
  }

  .board__container {
    margin-top: 40px;
    display: flex;
    width: 100%;
  }

  .contact-us-form__container {
    display: flex;
    width: 100%;
    padding-top: 33px;

    .form__box {
      width: 435px;
      margin-right: 32px;

      .guide-phone__box {
        display: flex;
        flex-direction: column;
        background: #f6f9fd;
        padding: 32px 20px;
        box-sizing: border-box;
        border: solid 1px $--gray-200;
        box-sizing: border-box;
        position: relative;

        & > header {
          display: flex;
          align-items: flex-start;

          h3 {
            color: $--gray-900;
          }
        }

        .time__box {
          display: flex;
          flex-direction: column;
          margin-top: 12px;

          .item {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            & > header {
              display: flex;
              align-items: center;
              min-width: 84px;

              .ico {
                margin-right: 4px;
              }
            }

            span {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--gray-900;
            }
          }
        }

        .illust {
          position: absolute;
          right: -35px;
          bottom: 0px;
          display: flex;
          width: 202px;
          height: 152px;
          background: url('../images/img-faq-pc-02@3x.png');
          background-size: cover;
        }
      }

      .guide-text__box {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 47px;

        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 1.43;
          color: $--gray-900;

          &.gray {
            color: $--gray-600;
          }

          em {
            font-weight: bold;
          }
        }
      }

      & > article {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .aggree__box {
        display: flex;
        align-items: center;
        margin-bottom: 27px;

        .option__box {
          margin-left: 15px;
          display: flex;
          height: 100%;
          align-items: center;

          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--main-500;
            text-decoration: underline;
          }
        }
      }
    }

    .map__box {
      flex: 1;
      display: flex;
      flex-direction: column;

      .daum-map__box {
        height: 665px;
        margin-bottom: 20px;
      }

      .text__box {
        display: flex;
        flex-direction: column;

        span {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
    }
  }

  .notice__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .serach__box {
      display: flex;
      align-items: center;
      margin-top: 36px;
      margin-bottom: 24px;
    }

    .board__box {
      margin-top: 28px;
      margin-bottom: 36px;
    }
  }

  .faq__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;

    .option__box {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      overflow: hidden;
      border: solid 1px $--gray-200;
      box-sizing: border-box;

      .guide__box {
        display: flex;

        .inquiry__box {
          width: 697px;
          display: flex;
          flex-direction: column;
          background: #f6f9fd;
          padding: 32px 20px;
          padding-bottom: 20px;
          box-sizing: border-box;
          border-bottom: solid 1px $--gray-200;
          position: relative;
          border-right: solid 1px $--gray-200;

          span {
            margin-top: 8px;
            margin-bottom: 8px;
            color: $--gray-900;
            font-size: 14px;
          }

          .illust {
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: flex;
            width: 310px;
            height: 152px;
            background: url('../images/img-faq-pc-01@3x.png');
            background-size: cover;
          }
        }

        .phone__box {
          flex: 1;
          display: flex;
          flex-direction: column;
          background: #f6f9fd;
          padding: 32px 20px;
          padding-bottom: 20px;
          box-sizing: border-box;
          border-bottom: solid 1px $--gray-200;
          position: relative;

          & > header {
            display: flex;
            align-items: flex-start;

            h3 {
              color: $--gray-900;
            }
          }

          .time__box {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: 10px;

            .item {
              display: flex;
              align-items: center;
              margin-bottom: 6px;

              & > header {
                display: flex;
                align-items: center;
                min-width: 84px;

                .ico {
                  margin-right: 4px;
                }
              }

              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: $--gray-900;
              }
            }
          }

          .illust {
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: flex;
            width: 202px;
            height: 152px;
            background: url('../images/img-faq-pc-02@3x.png');
            background-size: cover;
          }
        }
      }

      .serach__box {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      .category__box {
        display: flex;
        justify-content: center;
        margin-top: 28px;
        margin-bottom: 28px;
        padding: 0px 180px;
        box-sizing: border-box;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;
            box-sizing: border-box;
            border-radius: 4px;
            border: solid 1px $--main-300;
            background-color: $--main-100;
            height: 28px;
            margin-right: 12px;
            margin-bottom: 12px;

            &:last-child {
              margin-right: 0px;
            }

            &.on {
              background: $--main-500;

              span {
                color: #fff;
              }
            }

            span {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--main-500;
            }
          }
        }
      }
    }

    .board__box {
      margin-top: 28px;
      margin-bottom: 36px;
    }
  }

  .search__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: solid 1px $--gray-200;
    padding-bottom: 24px;

    &.between {
      justify-content: space-between;
    }

    &.contributor {
      .contributor-input__box {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .dropdown__box {
        display: flex;
        align-items: center;
      }
    }

    .input__box {
      display: flex;
      align-items: center;
    }

    .date__box {
      display: flex;
      align-items: center;
    }

    .select__box {
      display: flex;
      align-items: center;
    }

    .tab__box {
      width: 150px;
      height: 50px;
      margin-right: 16px;
      box-sizing: border-box;

      & > ul {
        display: flex;
        width: 100%;
        border-radius: 25px;
        height: 100%;

        li {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          span {
            color: #777777;
          }

          &.on {
            background: #5d3be5;

            span {
              color: #fff;
            }

            &:first-of-type {
              border-color: #5d3be5;
            }

            &:last-child {
              border-color: #cccccc;
            }
          }

          &:first-of-type {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            border-top: solid 1px #cccccc;
            border-bottom: solid 1px #cccccc;
          }

          &:last-child {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            border-top: solid 1px #cccccc;
            border-bottom: solid 1px #cccccc;
          }
        }
      }
    }
  }

  .project-header__container {
    display: flex;
    width: 100%;
    background: #f6f6f6;
    padding: 80px 0px;
    justify-content: center;

    .breadcrumb__box {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;

      ul {
        display: flex;

        li {
          &::after {
            content: '>';
            margin: 0px 10px;
            color: $--gray-900;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #777;

          &.active {
            font-weight: bold;
          }
        }
      }
    }

    .title__box {
      display: flex;
      width: 100%;
      align-content: center;
      justify-content: space-between;
      margin-bottom: 70px;

      .info {
        display: flex;

        li {
          margin-right: 40px;

          &:last-child {
            margin-right: 0px;
          }

          span {
            color: #777;

            &.value {
              color: $--gray-900;
              margin-left: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .row__box {
      display: flex;
      width: 100%;
      align-content: center;

      ul {
        display: flex;
        align-content: center;
        width: 100%;
        border-top: solid 1px #161616;

        li {
          flex: 1;
          display: flex;
          flex-direction: column;
          border-bottom: solid 1px #e3e3e3;
          border-right: solid 1px #e3e3e3;

          & > header {
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 70px;
            border-bottom: solid 1px #e3e3e3;
            box-sizing: border-box;

            span {
              text-align: center;
              font-size: 14px;
              font-weight: bold;
            }

            em {
              margin-top: 5px;
              font-size: 12px;
              font-weight: bold;
              color: #777;
            }
          }

          .value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 70px;
            background: #fff;

            span {
              font-size: 14px;
              font-weight: normal;
            }

            em {
              font-size: 12px;
            }

            .button__box {
              display: flex;

              button {
                min-width: 56px;
                height: 30px;
                border-radius: 8px;
                border: solid 1px #161616;
                background: #fff;
                margin-right: 4px;

                &:last-child {
                  margin-right: 0px;
                }

                &.main {
                  color: $--main-500;
                  border: solid 1px #b19ff9;
                  background-color: #f6f3ff;
                }
              }
            }
          }
        }
      }
    }

    .button-list__box {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .stat__container {
    display: flex;
    margin-top: 47px;
    width: 100%;

    ul {
      display: flex;
      width: 100%;

      li {
        flex: 1;
        border-radius: 8px;
        border: solid 1px #e3e3e3;
        margin-right: 16px;
        padding: 20px;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0px;
        }

        .title__box {
          margin-bottom: 20px;
        }

        .value__box {
          display: flex;

          .vluae-item {
            flex: 1;
            display: flex;
            flex-direction: column;

            &.no-border {
              &:first-of-type {
                border-right: none;
              }
            }

            &:first-of-type {
              border-right: solid 1px #e3e3e3;
              padding-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }

            & > header {
              margin-bottom: 10px;

              span {
                font-size: 14px;
              }
            }

            .credit__box {
              display: flex;
              align-items: flex-end;
              margin-bottom: 5px;

              .red {
                color: #d73b3b;
              }

              .blue {
                color: #304ee3;
              }

              em {
                font-size: 12px;
                color: #777;
                margin-left: 6px;
              }
            }

            .caption__box {
              height: 20px;
              align-items: flex-end;
              display: flex;

              em {
                color: #777;
                font-size: 12px;
              }

              b {
                font-size: 12px;
                margin: 0px 2px;
              }
            }
          }
        }
      }
    }
  }

  //marketplace

  .kakao-error__box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;

      h1 {
        margin-bottom: 24px;
      }

      span {
        margin-bottom: 12px;
      }
    }
  }

  .illust__container {
    display: flex;
    width: 100%;
    height: 640px;
    flex-direction: column;
    align-items: center;
    background-image: url('../images/kv@3x.png');
    background-position: center;
    background-size: cover;

    .text__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      min-width: $wrapper-min-width;
      max-width: $wrapper-min-width;

      h1 {
        margin-top: 56px;
        color: #fff;
        font-weight: normal;
      }

      b {
        margin-top: 82px;
        font-size: 48px;
        color: #fff;
        font-weight: normal;
        line-height: 1.33;
      }

      h2 {
        margin-top: 28px;
        color: #fff;
        font-weight: normal;
      }
    }
  }

  .table__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title__container {
    width: 100%;
    padding: 56px 0 10px;
    .title__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 10px;
    }
    .badge__box {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      p {
        font-size: 16px;
        position: relative;
        margin-right: 10px;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 80%;
          top: 2px;
          background-color: $--gray-400;
          margin-left: 10px;
        }
      }
      .badge__item {
        padding: 2px 15px;
        border-radius: 25px;
        border: 1px solid;
        margin-left: 10px;
        &.area,
        &.sub_area {
          border: 1px solid $--main-500;
          color: $--main-500;
        }
      }
      @media only screen and (max-width: 1136px) {
        .badge__item {
          padding: 2px 10px;
          margin-left: 8px;
          font-size: 14px;
          &:first-of-type {
            margin-left: 10px;
          }
        }
      }
    }
    .icon_share {
      width: 40px;
      height: 40px;
      background-image: url('../../styles/icon/icon_share.svg');
      cursor: pointer;
      @media only screen and (max-width: 1136px) {
        width: 30px;
        height: 30px;
        background-size: cover;
      }
    }
  }

  .puchase__container {
    display: flex;
    width: 100%;
    margin-top: 30px;
    .main__box {
      flex: 1;
      display: flex;
      margin-right: 36px;
      flex-direction: column;
      .img__box {
        display: flex;
        width: 100%;
        height: 600px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .complte__box {
          z-index: 100;
          position: absolute;
          display: flex;
          right: 8px;
          bottom: 72px;
          width: 97px;
          height: 32px;
          background: #fff;
          border-radius: 4px;
          align-items: center;
          justify-content: center;

          span {
            font-size: 14px;
            margin-left: 4px;
            color: $--main-500;
          }
        }

        .img-list__box {
          position: absolute;
          bottom: 8px;
          right: 8px;
          display: flex;
          z-index: 10;
          ul {
            display: flex;

            li {
              width: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              border-radius: 8px;
              overflow: hidden;
              border: solid 1px transparent;
              cursor: pointer;

              &.on {
                border: solid 1px #161616;
              }

              &:last-child {
                margin-right: 0px;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .tab__box {
        margin-top: 16px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px $--gray-200;
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        /* required */
        background: #fff;
        z-index: 10;

        ul {
          display: flex;

          li {
            cursor: pointer;
            width: 160px;
            height: 48px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2px;
            span {
              color: $--grey-500;
            }
            &:last-child {
              margin-right: 0px;
            }

            &.on {
              // background: $--main-500;
              // box-shadow: inset 0 1em gold;
              border-bottom: 3px solid $--main-500;
              font-weight: 800;
              span {
                color: $--main-500;
              }
            }
          }
        }
      }

      .project__box {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        & > header {
          margin-bottom: 16px;
        }

        .project__icon {
          margin-bottom: 30px;
          .icon-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 120px;
          }
          .img-box {
            width: 100px;
            img {
              width: 100%;
            }
          }
        }

        padding-bottom: 36px;
        border-bottom: solid 1px $--gray-200;
        .project__info {
          padding: 30px;
          margin-top: 20px;
          background-color: $--main-100;
          border-radius: 20px;
          li {
            margin-bottom: 5px;
            &.map-container {
              padding-top: 20px;
            }
          }
          span:first-of-type {
            font-weight: 800;
            padding-right: 10px;
            line-height: 1.5;
          }
          @media only screen and (max-width: 1136px) {
            padding: 16px;
          }
        }
      }

      .banner__box {
        margin-top: 36px;
        margin-bottom: 36px;
        display: flex;
        flex-direction: column;
        padding: 36px 24px;
        box-sizing: border-box;
        background: #f7f7f9;

        .banner__item {
          display: flex;
          flex-direction: column;

          margin-bottom: 20px;

          h2 {
            margin-bottom: 4px;
            line-height: 1.67;

            em {
              font-weight: normal;
            }
          }

          span {
            line-height: 1.5;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .contributor__box {
        margin-top: 56px;
        display: flex;
        flex-direction: column;

        & > header {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .more {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #161616;
            font-size: 16px;
          }
        }

        .contributors__list {
          margin-top: 20px;
          display: flex;

          .contributors__item {
            flex: 1;
            max-width: 365px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            border-radius: 8px;
            border: solid 1px #dadada;
            padding: 20px;
            box-sizing: border-box;

            &:first-of-type {
              margin-right: 20px;
            }

            .main-info__box {
              display: flex;
              overflow: hidden;
              width: 100%;

              .img__box {
                display: flex;
                min-width: 116px;
                min-height: 116px;
                max-width: 116px;
                max-height: 116px;
                border-radius: 50%;
                overflow: hidden;
                width: 100%;
                margin-right: 16px;
                background: #efefef;

                img {
                  width: 100%;
                  object-fit: cover;
                }
              }

              .type__box {
                display: flex;
                flex: 1;
                flex-direction: column;

                .name-date__box {
                  display: flex;
                  justify-content: space-between;

                  .text__box {
                    span {
                      font-size: 14px;
                      color: #aaaaaa;

                      &.name {
                        padding-right: 8px;
                        margin-right: 8px;
                        border-right: solid 1px #eeeeee;
                      }
                    }
                  }

                  .forward {
                    background: #faf9ff;
                    border: solid 1px #ded6ff;
                    color: #5d3be5;
                    font-size: 12px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 8px;
                  }
                }

                .name__box {
                  margin-top: 10px;
                  display: flex;
                  flex-direction: column;

                  .ton {
                    margin-top: 10px;
                    display: flex;
                    align-items: flex-end;
                    font-size: 20px;
                  }

                  h3 {
                    display: flex;
                    font-weight: normal;

                    em {
                      margin-left: 4px;
                      font-size: 14px;
                      margin-bottom: 1px;
                    }
                  }
                }
              }
            }

            .detail__box {
              margin-top: 16px;
              display: flex;
              flex-direction: column;

              .id {
                color: #aaaaaa;
                margin-top: 4px;
              }
            }

            .reason__box {
              margin-top: 12px;
              display: flex;
              justify-content: space-between;

              .text__box {
                display: flex;
                flex-direction: column;

                .certNumber {
                  margin-top: 6px;
                  color: #aaaaaa;
                }
              }

              .cert-img__box {
                display: flex;
              }
            }
          }
        }
      }
    }

    .m-sub__box {
      display: none;
    }

    .sub__box {
      width: 350px;
      display: flex;
      flex-direction: column;

      .id__box {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: $--gray-400;
        }
      }

      .type__box {
        display: flex;
        margin-top: 12px;

        .area__box {
          margin-right: 8px;
          display: flex;
          align-items: center;
        }

        .sub-area__box {
          padding-right: 8px;
          margin-right: 8px;
          border-right: solid 1px $--gray-200;
        }

        .trade__box {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $--main-500;
        }
      }
    }

    .title__box {
      display: flex;
      margin-top: 28px;
    }

    .area-subArea__box {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      .header {
        margin-bottom: 4px;

        span {
          color: $--gray-400;
        }
      }

      .value {
        .arrow {
          margin-right: 8px;
          margin-left: 8px;
        }
      }
    }

    .price__box {
      margin-top: 36px;
      display: flex;

      h1 {
        font-weight: 600;
        em {
          margin-left: 8px;
          font-weight: normal;
          font-size: 16px;
          color: #666;
        }
      }
    }

    .date__box {
      display: flex;
      align-items: center;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #666;
      }
    }

    .purchase__container {
      display: flex;
      // margin-top: 92px;
      flex-direction: column;

      .normal__box {
        margin-top: 20px;
      }

      .forward__box,
      .normal__box {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: solid 1px #dadada;
        background-color: $--gray-0;
        width: 100%;

        & > header {
          padding: 0px 20px;
          display: flex;
          box-sizing: border-box;
          justify-content: space-between;
          height: 50px;
          align-items: center;
          border-bottom: solid 1px #dadada;
          box-sizing: border-box;

          .type {
            display: flex;
            align-items: center;

            span {
              font-weight: bold;
              margin-right: 4px;

              em {
                color: $--main-500;
              }
            }
          }

          .amount {
            display: flex;
            border-radius: 16px;
            border: solid 1px #dadada;
            height: 32px;
            align-items: center;
            padding: 0px 12px;

            em {
              font-size: 14px;
              color: $--main-500;
              font-weight: bold;
              margin-right: 4px;
            }

            span {
              font-size: 14px;
            }
          }
        }

        .action__box {
          display: flex;
          flex-direction: column;
          padding: 24px 20px;
          box-sizing: border-box;

          .ton__box {
            display: flex;
            box-sizing: border-box;
            justify-content: space-between;

            h1 {
              font-weight: 700;
              letter-spacing: -0.5px;
              em {
                margin-left: 8px;
                font-weight: normal;
                font-size: 16px;
              }
            }

            .status__box {
              display: flex;
              border-radius: 4px;
              border: solid 1px $--main-500;
              background-color: $--gray-0;
              height: 32px;
              padding: 0px 10px;
              box-sizing: border-box;
              align-items: center;

              &.ready {
                border-color: #f41515;

                span {
                  color: #f41515;
                }
              }

              &.SoldOut {
                border-color: transparent;
                padding: 0px;
              }

              span {
                font-size: 14px;
                color: $--main-500;
              }
            }
          }

          .vrc-meaning {
            padding: 20px 0 10px;
            p {
              font-size: 14px;
              line-height: 1.3;
              color: $--gray-600;
              span {
                font-size: inherit;
                line-height: inherit;
                &.key {
                  color: $--main-500;
                  font-weight: 700;
                }
              }
              &::before {
                content: '';
                width: 3px;
                height: 3px;
              }
            }
          }

          .button__box {
            display: flex;
            margin-top: 16px;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            background-color: $--main-500;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;

            &.disabled {
              pointer-events: none;
              background-color: #eeeeee;
              color: #161616;
            }
          }
        }
      }
    }
  }

  .mobile-main-__container {
    display: none;
  }

  .purchase-list__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .serach__box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 56px;
    }

    .list__box {
      margin-top: 38px;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        padding-bottom: 13px;
        border-bottom: solid 1px #777777;

        li {
          display: flex;
          width: 100%;
          flex: 1;
          padding: 0px 16px;
          justify-content: center;

          &:first-of-type {
            max-width: 120px;
          }

          &:nth-child(3) {
            max-width: 180px;
          }

          &:nth-child(4) {
            max-width: 120px;
          }
        }
      }

      .data {
        display: flex;
        flex-direction: column;

        .empty__box {
          display: flex;
          height: 56px;
          align-items: center;
        }

        li {
          display: flex;
          width: 100%;
          padding-top: 36px;
          padding-bottom: 36px;
          border-bottom: solid 1px #eeeeee;

          .date {
            display: flex;
            align-items: center;
            flex: 1;
            max-width: 100px;
            padding: 0px 16px;

            em {
              display: none;
            }
          }

          .title {
            flex: 1;
            padding: 0px 16px;
            display: flex;
            cursor: pointer;

            .img__box {
              margin-right: 32px;
              display: flex;
              width: 156px;
              height: 120px;
              border-radius: 12px;
              overflow: hidden;
              border: 1px solid $--gray-200;
              background-color: $--gray-100;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .name {
              display: flex;
              margin-bottom: 8px;
              margin-top: 16px;
              font-size: 18px;
            }

            .id {
              display: flex;
              margin-bottom: 20px;
              color: #aaaaaa;
            }

            .total-price {
              display: none;
            }
          }

          .order {
            flex: 1;
            padding: 0px 16px;
            max-width: 132px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            em {
              font-size: 14px;
              color: #aaaaaa;
              margin-bottom: 10px;
            }
          }

          .button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 0px 16px;
            max-width: 120px;

            .detail-button {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #5d3be5;
              border-radius: 8px;
              border: solid 1px #5d3be5;
              height: 38px;
              margin-bottom: 8px;
              font-size: 14px;
            }

            .cert-button {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              height: 38px;
              border: solid 1px #d8d8d8;
              font-size: 14px;
            }

            .item-status {
              color: $--main-500;
              font-size: 14px;
              font-weight: 600;
              text-align: center;
              padding-top: 10px;
              word-break: keep-all;
              p {
                font: inherit;
              }
            }
          }
        }
      }
    }
  }

  .purchase-detail__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 56px;

    .m-receipt__box {
      display: none;
    }

    .m-order-number__box {
      display: none;
    }

    .order__box {
      display: flex;
      flex-direction: column;

      & > header {
        margin-bottom: 18px;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          border-top: solid 1px #777777;
          background: #f7f7f9;
          height: 50px;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding: 0px 16px;

            &:first-of-type {
              max-width: 90px;
            }

            &:last-child {
              max-width: 120px;
            }
          }
        }

        .data {
          display: flex;
          padding: 36px 0px;
          border-bottom: solid 1px #eeeeee;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 0px 16px;

            &:first-of-type {
              max-width: 90px;
              color: $--main-500;
              font-weight: bold;
            }

            &:nth-child(2) {
              .img__box {
                margin-right: 32px;
                margin-left: 32px;
                display: flex;
                width: 156px;
                height: 120px;
                border-radius: 12px;
                overflow: hidden;
                border: 1px solid $--gray-200;
                background-color: $--gray-100;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .name {
                display: flex;
                margin-bottom: 8px;
                margin-top: 0px;
                font-size: 18px;
              }

              .id {
                display: flex;
                margin-bottom: 20px;
                color: #aaaaaa;
              }
            }

            &:last-child {
              max-width: 120px;
              justify-content: center;

              .cert-button {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: solid 1px $--main-500;
                background-color: $--main-500;
                width: 120px;
                height: 38px;
                font-size: 14px;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .payment__box {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      & > header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }

        .status {
          font-size: 14px;
          color: $--main-500;
          margin-left: 16px;
          line-height: 21px;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 50px;
          box-sizing: border-box;
          background: #f7f7f9;
          border-top: solid 1px #000;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;

            &:first-of-type {
              max-width: 140px;
            }

            &:last-child {
              max-width: 120px;
              justify-content: center;
            }
          }
        }

        .data {
          display: flex;
          // height         : 56px;
          box-sizing: border-box;
          background: #fff;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;
          min-height: 56px;

          li {
            display: flex;
            flex: 1;
            font-size: 14px;

            &:first-of-type {
              max-width: 140px;
              font-size: 12px;
            }

            &:last-child {
              max-width: 120px;
              justify-content: center;
            }

            .button {
              cursor: pointer;
              width: 120px;
              border-radius: 8px;
              border: solid 1px #d8d8d8;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 38px;
            }

            .vank__box {
              padding: 18px 0px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;

              .detail__list {
                margin-top: 8px;
                display: flex;
                flex-direction: column;

                .detail__item {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  margin-bottom: 4px;

                  &:last-child {
                    margin-bottom: 0px;
                  }

                  span {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .buyer__box {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      & > header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }

        .status {
          font-size: 14px;
          color: $--main-500;
          margin-left: 16px;
          line-height: 21px;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 50px;
          box-sizing: border-box;
          background: #f7f7f9;
          border-top: solid 1px #000;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;

            &:first-of-type {
              max-width: 140px;
            }

            &.present-email {
              max-width: 120px;
              justify-content: center;
            }
          }
        }

        .data {
          display: flex;
          height: 56px;
          box-sizing: border-box;
          background: #fff;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;
            font-size: 14px;

            &:first-of-type {
              max-width: 140px;
            }

            &.present-email {
              max-width: 120px;
              justify-content: center;
            }

            &.present-person {
              display: flex;
              align-items: center;

              .ico {
                margin-right: 4px;
              }
            }

            .button {
              cursor: pointer;
              width: 120px;
              border-radius: 8px;
              border: solid 1px #d8d8d8;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 38px;
            }
          }
        }
      }
    }

    .button__box {
      display: flex;
      margin-top: 36px;
      justify-content: center;

      .button {
        cursor: pointer;
        width: 160px;
        height: 50px;
        border-radius: 25px;
        border: solid 1px $--gray-300;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .forward-purchase-list__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .serach__box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 36px;
      padding-bottom: 24px;
      border-bottom: solid 1px #777777;
    }

    .filter__box {
      padding: 20px 0px;
      display: flex;
      align-items: center;
    }

    .list__box {
      display: flex;

      & > ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .empty {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          // height: 50px;
          span {
            padding: 10px;
          }
        }

        li {
          border-bottom: solid 1px #eeeeee;

          &:first-of-type {
            border-top: solid 1px #eeeeee;
          }

          display: flex;
          width: 100%;

          .info__box {
            padding: 36px 0px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: 32px;
            box-sizing: border-box;

            .status__box {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: flex-end;

              span {
                font-size: 14px;
              }

              .status {
                display: flex;
                align-items: center;

                .Complete {
                  color: #5d3be5;
                }

                .DepositReady,
                .DepositCancel {
                  color: #f41515;
                }
              }

              .date {
                margin-left: 16px;
                font-size: 14px;
              }

              .order {
                span {
                  font-size: 14px;
                  color: #aaaaaa;
                }
              }
            }

            .caption__box {
              display: flex;
              margin-top: 16px;

              .img__box {
                display: flex;
                min-width: 127px;
                min-height: 98px;
                max-width: 127px;
                max-height: 98px;
                border-radius: 12px;
                overflow: hidden;
                margin-right: 32px;

                img {
                  width: 100%;
                }
              }

              .title__box {
                flex: 1;
                display: flex;
                flex-direction: column;

                .name {
                  display: flex;
                  margin-bottom: 8px;
                  margin-top: 4px;
                  font-size: 18px;
                }

                .id {
                  display: flex;
                  margin-bottom: 20px;
                  color: #aaaaaa;
                }

                .total-price {
                  display: none;
                }
              }

              .price__box {
                display: flex;
                align-items: flex-end;
                padding-bottom: 9px;

                span {
                  white-space: nowrap;
                }
              }
            }
          }

          .option__box {
            padding: 36px 0px;
            box-sizing: border-box;
            display: flex;
            width: 350px;
            background: #f7f7f9;

            .forward-status {
              margin-left: 32px;
              display: flex;
              padding-right: 32px;
              margin-top: 40px;
              border-right: solid 1px #eeeeee;

              .ready {
                display: flex;
                flex-direction: column;
                margin-right: 20px;
                align-items: center;

                span {
                  margin-bottom: 12px;
                }

                b {
                  font-weight: normal;
                }
              }

              .complete {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                  margin-bottom: 12px;
                  color: #aaaaaa;
                }

                b {
                  color: #aaaaaa;
                  font-weight: normal;
                }
              }
            }

            .button__box {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 32px;

              .button {
                cursor: pointer;
                border-radius: 8px;
                border: solid 1px $--main-500;
                display: flex;
                width: 120px;
                height: 38px;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $--main-500;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .forward-purchase-detail__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 56px;

    .m-receipt__box {
      display: none;
    }

    .m-order-number__box {
      display: none;
    }

    .order__box {
      display: flex;
      flex-direction: column;

      & > header {
        margin-bottom: 18px;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          border-top: solid 1px #777777;
          background: #f7f7f9;
          height: 50px;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding: 0px 16px;

            &:first-of-type {
              max-width: 90px;
            }
          }
        }

        .data {
          display: flex;
          padding: 36px 0px;
          border-bottom: solid 1px #eeeeee;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 0px 16px;

            &:first-of-type {
              max-width: 90px;
              color: $--main-500;
              font-weight: bold;
            }

            .forward-type__box {
              display: flex;
              margin-bottom: 4px;
              height: 24px;
              padding: 4px 8px;
              box-sizing: border-box;
              border: solid 1px #ded6ff;
              background: #faf9ff;
              width: 64px;

              span {
                font-size: 12px;
                color: #5d3be5;
              }
            }

            &:nth-child(2) {
              .img__box {
                margin-right: 32px;
                display: flex;
                width: 156px;
                height: 120px;
                border-radius: 12px;
                overflow: hidden;

                img {
                  width: 100%;
                }
              }

              .name {
                display: flex;
                margin-bottom: 8px;
                margin-top: 0px;
                font-size: 18px;
              }

              .id {
                display: flex;
                margin-bottom: 20px;
                color: #aaaaaa;
              }
            }
          }
        }
      }
    }

    .payment__box {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      & > header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }

        .status {
          font-size: 14px;
          color: $--main-500;
          margin-left: 16px;
          line-height: 21px;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 50px;
          box-sizing: border-box;
          background: #f7f7f9;
          border-top: solid 1px #000;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;

            &:first-of-type {
              max-width: 140px;
            }

            &:last-child {
              max-width: 120px;
              justify-content: center;
            }
          }
        }

        .data {
          display: flex;
          min-height: 56px;
          box-sizing: border-box;
          background: #fff;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;
            font-size: 14px;

            .vank__box {
              padding: 18px 0px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;

              .detail__list {
                margin-top: 8px;
                display: flex;
                flex-direction: column;

                .detail__item {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  margin-bottom: 4px;

                  &:last-child {
                    margin-bottom: 0px;
                  }

                  span {
                    font-size: 14px;
                  }
                }
              }
            }

            &:first-of-type {
              max-width: 140px;
              font-size: 12px;
            }

            &:last-child {
              max-width: 120px;
              justify-content: center;
            }

            .button {
              cursor: pointer;
              width: 120px;
              border-radius: 8px;
              border: solid 1px #d8d8d8;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 38px;
            }
          }
        }
      }
    }

    .buyer__box {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      & > header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
        }

        .status {
          font-size: 14px;
          color: $--main-500;
          margin-left: 16px;
          line-height: 21px;
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 50px;
          box-sizing: border-box;
          background: #f7f7f9;
          border-top: solid 1px #000;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;

            &:first-of-type {
              max-width: 140px;
            }

            &:nth-child(2) {
              max-width: 240px;
              justify-content: center;
              justify-content: flex-start;
            }

            &:last-child {
              justify-content: flex-start;
            }
          }
        }

        .data {
          display: flex;
          height: 56px;
          box-sizing: border-box;
          background: #fff;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;
            font-size: 14px;

            &:first-of-type {
              max-width: 140px;
            }

            &:nth-child(2) {
              max-width: 240px;
              justify-content: flex-start;
            }

            &:last-child {
              justify-content: flex-start;
            }

            .gift__box {
              display: flex;
              align-items: center;

              .ico {
                margin-right: 4px;
              }
            }

            .button {
              cursor: pointer;
              width: 120px;
              border-radius: 8px;
              border: solid 1px #d8d8d8;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 38px;
            }
          }
        }
      }
    }

    .calculate__box {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      &.company {
        .table__box .header li:last-child {
          max-width: 200px;
        }
        .table__box .data__wrapper .data li {
          border-right: none;
        }
      }

      .summary__box {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        .header {
          display: flex;
          border-top: solid 1px #777777;
          background: #f7f7f9;
          height: 50px;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding: 0px 16px;
          }
        }
        .data {
          display: flex;
          height: 57px;
          border-top: solid 1px #eeeeee;
          border-bottom: solid 1px #eeeeee;

          li {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            padding: 0px 16px;
          }
        }
      }
      .m-table__box {
        display: none;
      }

      .m-account__box {
        display: none;
      }

      & > header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #000000;
          font-weight: bold;

          &.desc {
            font-size: 14px;
            margin-left: 16px;
            color: #555b67;
            font-weight: normal;
          }
        }
      }

      .table__box {
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 50px;
          box-sizing: border-box;
          background: #f7f7f9;
          border-top: solid 1px #000;
          border-bottom: solid 1px #eeeeee;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;
          box-sizing: border-box;

          li {
            display: flex;
            flex: 1;

            &:first-of-type {
              max-width: 200px;
            }

            &:last-child {
              max-width: 136px;
              justify-content: center;
            }
          }
        }

        .empty__box {
          height: 56px;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 16px;
        }

        .data__wrapper {
          display: flex;

          .data {
            display: flex;
            flex-direction: column;
            flex: 1;

            li {
              display: flex;
              height: 56px;
              align-items: center;
              border-bottom: solid 1px #eeeeee;
              border-right: solid 1px #eeeeee;

              .date {
                padding-left: 16px;
                display: flex;
                max-width: 200px;
                width: 100%;

                span {
                  font-size: 14px;
                }

                .ton {
                  display: flex;
                  align-items: center;
                  margin-left: 16px;
                }
              }

              .status {
                display: flex;

                b {
                  font-size: 14px;
                  margin-right: 16px;
                }

                span {
                  font-size: 14px;
                }
              }

              .amount {
                flex: 1;
              }

              .cert {
                width: 200px;
                justify-content: center;
                display: flex;
                padding-right: 16px;

                .button {
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 8px;
                  height: 38px;
                  border: solid 1px #d8d8d8;
                  font-size: 14px;
                  width: 120px;
                }
              }
            }
          }

          .button__box {
            width: 168px;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: solid 1px #eeeeee;

            .button {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              height: 38px;
              border-radius: 8px;
              border: solid 1px #d8d8d8;
              font-size: 14px;
              color: #555b67;
            }
          }
        }
      }
    }

    .button-list__box {
      display: flex;
      margin-top: 36px;
      justify-content: center;

      .button {
        cursor: pointer;
        width: 160px;
        height: 50px;
        border-radius: 25px;
        border: solid 1px $--gray-300;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sell-list__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .serach__box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 36px;
      padding-bottom: 24px;
      border-bottom: solid 1px #777777;
    }

    .filter__box {
      padding: 20px 0px;
      display: flex;
      align-items: center;

      .select__box {
        display: flex;
        align-items: center;

        .form-select-box {
          margin-right: 16px;
        }
      }
    }

    .list__box {
      display: flex;

      & > ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .empty {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          span {
            padding: 10px;
          }
          // height: 50px;
        }

        li {
          border-bottom: solid 1px #eeeeee;

          &:first-of-type {
            border-top: solid 1px #eeeeee;
          }

          display: flex;
          width: 100%;

          .info__box {
            padding: 36px 0px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: 32px;
            box-sizing: border-box;

            .status__box {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: flex-end;

              span {
                font-size: 14px;
              }

              .status {
                display: flex;
                align-items: center;

                .Complete {
                  color: #5d3be5;
                }

                .DepositReady,
                .DepositCancel {
                  color: #f41515;
                }
              }

              .date {
                margin-left: 16px;
                font-size: 14px;
              }

              .order {
                display: flex;
                align-items: center;

                .forward-type__box {
                  display: flex;
                  margin-right: 8px;
                  height: 24px;
                  padding: 4px 8px;
                  box-sizing: border-box;
                  border: solid 1px #ded6ff;
                  background: #faf9ff;

                  span {
                    font-size: 12px;
                    color: #5d3be5;
                  }
                }

                span {
                  font-size: 14px;
                  color: #aaaaaa;
                }
              }
            }

            .caption__box {
              display: flex;
              margin-top: 16px;

              .img__box {
                display: flex;
                min-width: 127px;
                min-height: 98px;
                max-width: 127px;
                max-height: 98px;
                border-radius: 12px;
                overflow: hidden;
                margin-right: 32px;

                img {
                  width: 100%;
                }
              }

              .title__box {
                flex: 1;
                display: flex;
                flex-direction: column;

                .forward-type__box {
                  display: none;
                }

                .name {
                  display: flex;
                  margin-bottom: 8px;
                  margin-top: 4px;
                  font-size: 18px;
                }

                .id {
                  display: flex;
                  margin-bottom: 18px;
                  color: #aaaaaa;
                }

                .buyer {
                  span {
                    color: #777777;
                  }
                }
              }
            }
          }

          .option__box {
            padding: 36px 0px;
            box-sizing: border-box;
            display: flex;
            width: 350px;
            background: #f7f7f7;

            .price__box {
              display: flex;
              flex-direction: column;
              align-items: center;
              flex: 1;
              justify-content: center;
              border-right: solid 1px #eeeeee;

              .total__box {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                & > header {
                  display: none;
                }

                span {
                  font-size: 16px;
                }
              }

              .ton__box {
                display: flex;
                align-items: center;

                span {
                  font-size: 14px;
                  color: #555b67;
                }
              }
            }

            .button__box {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;

              .button {
                cursor: pointer;
                border-radius: 8px;
                border: solid 1px $--main-500;
                display: flex;
                width: 120px;
                height: 38px;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $--main-500;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .calculate-stat__container {
    display: flex;
    width: 100%;
    background: #f7f7f7;
    margin-top: 56px;
    padding: 8px;
    box-sizing: border-box;

    .current__box {
      display: flex;
      flex-direction: column;
      flex: 1;
      box-sizing: border-box;
      padding: 24px;
      background: #fff;
      border: solid 1px #eeeeee;
      margin-right: 9px;

      .total__box {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        border-bottom: solid 1px #d8d8d8;

        & > header {
          display: flex;
          align-items: center;

          h3 {
            font-weight: normal;
          }
        }

        .price {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          h1 {
            font-weight: normal;
          }
        }
      }

      .accumulate__box {
        display: flex;
        margin-top: 23px;
        justify-content: space-between;

        & > header {
          h3 {
            span {
              font-weight: normal;
              font-size: 14px;
              margin-left: 8px;
            }
          }
        }

        .price {
          h3 {
            font-weight: normal;
          }
        }
      }

      .expect__box {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;

        & > header {
          h3 {
            span {
              font-weight: normal;
              font-size: 14px;
              margin-left: 8px;
            }
          }
        }

        .price {
          h3 {
            font-weight: normal;
          }
        }
      }

      .guide__box {
        display: flex;
        margin-top: 89px;
        flex-direction: column;

        & > header {
          font-size: 14px;
          margin-bottom: 8px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .withdraw__box {
      display: flex;
      flex-direction: column;
      flex: 1;
      box-sizing: border-box;
      padding: 24px;

      .total__box {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        border-bottom: solid 1px #d8d8d8;

        & > header {
          display: flex;
          align-items: center;

          h3 {
            font-weight: normal;
          }
        }

        .price {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          h1 {
            font-weight: normal;
            color: #5d3be5;
          }
        }
      }

      .won__box {
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
        align-items: center;

        .input__box {
          width: 320px;
          height: 50px;
          border-radius: 8px;
          border: solid 1px #d8d8d8;
          box-sizing: border-box;
          padding: 0px 16px;
          background: #fff;
          display: flex;
          align-items: center;

          input {
            padding: 8px;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            text-align: light;
            border: none;
            font-size: 18px;
          }

          em {
            font-size: 18px;
          }
        }
      }

      .caution__box {
        margin-top: 22px;
        display: flex;
        flex-direction: column;

        & > header {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          color: #555b67;
        }

        span {
          font-size: 14px;
          line-height: 1.43;
        }
      }

      .agree__box {
        margin-top: 18px;

        .label__box {
          span {
            color: #777777;
          }
        }
      }

      .button__box {
        display: flex;
        margin-top: 35px;

        .account-button {
          cursor: pointer;
          display: flex;
          height: 50px;
          width: 140px;
          border-radius: 25px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #555b67;
          margin-right: 16px;
        }

        .withdraw-button {
          cursor: pointer;
          display: flex;
          height: 50px;
          flex: 1;
          border-radius: 25px;
          background: #5d3be5;
          color: #fff;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .calculate-filter__box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;

    .date__box {
      display: flex;
      align-items: center;

      .form-select-box {
        margin-right: 16px;
      }
    }

    .input__box {
      display: flex;
      align-items: center;

      .form-select-box {
        display: none;
      }
    }
  }

  .calculate-summary__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 36px;

    .desc {
      font-size: 14px;
    }

    .summary {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: bold;

        &:first-of-type {
          padding-right: 8px;
          margin-right: 8px;
          border-right: solid 1px #eeeeee;
        }

        em {
          color: #5d3be5;
        }
      }
    }
  }

  .mypage-nav__container {
    display: none;
  }

  .mobile-purchase-button__container {
    display: none;
  }
}

.online-terms__box {
  display: flex;
  padding: 12px;
  box-sizing: border-box;
  overflow-y: auto;
  flex-direction: column;

  h1 {
    margin: 8px 0px;
    font-size: 18px;
  }

  strong {
    margin: 8px 0px;
    font-size: 16px;
  }

  p {
    margin: 8px 0px;
    white-space: pre-line;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $--grey-500;
  }

  li {
    font-size: 14px;
    color: $--grey-500;
  }

  table {
    border: solid 1px #d1d1d1;
    margin-bottom: 12px;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    p {
      word-break: keep-all;
    }

    thead {
      background: #dbdbdb;

      th {
        text-align: center;
      }
    }

    tbody {
      td {
        padding: 0px 8px;
        border-right: solid 1px #d1d1d1;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1136px) {
  .basic-page {
    .brand__logo {
      margin-top: 64px;
      img {
        display: block;
        margin: 0 auto;
        width: 400px;
      }
    }
    .headding-text__container {
      margin-top: 28px;

      & > header {
        text-align: center;

        h1 {
          font-size: 24px;
        }
      }
    }

    .mobile-main-__container {
      display: flex;
      height: 122px;
      border-bottom: solid 11px #f7f7f9;
      width: 100%;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 12px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.43;
        color: $--main-500;
      }
    }

  }
}

@media only screen and (max-width: 1136px) {
  .basic-page {
    .empty__box {
      padding-left: 16px !important;
    }

    // .forward-purchase-list__container .list__box > ul .empty {
    //   padding-left: 16px !important;
    //   box-sizing: border-box;
    // }

    // .sell-list__container .list__box > ul .empty {
    //   padding-left: 16px !important;
    //   box-sizing: border-box;
    // }

    .list__box > ul .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      height: 100px;
      span {
        padding: 10px;
        color: $--gray-600;
      }
    }

    .illust__container {
      background: url('../images_m/bitmap-3@3x.png');
      height: 348px;
      background-position: center;
      background-size: cover;

      .text__wrapper {
        min-width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0px 16px;

        h1 {
          font-size: 24px;
          margin-top: 36px;
        }

        b {
          font-size: 24px;
          margin-top: 36px;
          line-height: 1.67;
        }

        h2 {
          margin-top: 24px;
          font-size: 14px;
        }
      }
    }

    .search__container {
      box-sizing: border-box;
      padding: 0px 16px;
      flex-direction: column;
      width: 100%;
      margin-top: 24px;
      padding-bottom: 24px;

      &.between {
        .select__box {
          width: 100%;
          margin-bottom: 8px;

          .form-select-box {
            width: 50% !important;
          }
        }

        .input__box {
          width: 100%;

          .form-dropdown-input {
            width: 100% !important;
          }

          .basic-button {
            min-width: 80px;
          }
        }
      }

      &.contributor {
        .contributor-input__box {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;

          .tab__box {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 16px;
          }

          .dropdown__box {
            width: 100%;
            display: flex;
            align-items: center;

            .form-dropdown-input {
              width: 100% !important;
            }

            .basic-button {
              min-width: 80px;
            }
          }
        }
      }
    }

    .headding-text__container {
      display: none;
    }

    .title__container {
      padding: 24px;
      .badge__box {
        p {
          font-size: 14px;
        }
      }
    }

    .puchase__container {
      margin-top: 0px;
      width: 100%;

      .main__box {
        margin-right: 0px;
        max-width: 100vw;
        position: relative;
        .img__box {
          border-radius: 0;
          height: 300px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .tab__box {
          width: 100%;

          ul {
            width: 100%;
            display: flex;

            li {
              width: auto;
              flex: 1;
              max-width: 160px;
            }
          }
        }

        .project__box {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .banner__box {
          margin-left: -16px;
          width: 100vw;
        }

        .contributor__box {
          padding: 0px 16px;
          box-sizing: border-box;

          .contributors__list {
            flex-direction: column;
            width: 100%;

            .contributors__item {
              width: 100%;
              max-width: 100%;

              &:first-of-type {
                margin-bottom: 16px;
              }

              .detail__box {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: flex-end;

                .id {
                  min-width: 140px;
                }
              }

              .reason__box {
                .text__box {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: row-reverse;
                  justify-content: flex-end;
                  align-items: center;

                  .certNumber {
                    min-width: 140px;
                  }
                }

                .cert-img__box {
                  display: none;
                }
              }
            }
          }
        }
      }

      .m-sub__box {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 16px;
        margin-top: 16px;
        box-sizing: border-box;
        padding-bottom: 32px;
        border-bottom: 10px solid $--gray-200;
        .id-type__box {
          display: flex;
          justify-content: space-between;
        }

        .id__box {
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            color: $--gray-400;
          }
        }

        .title__box {
          margin-top: 20px;
        }

        .area-subArea__box {
          display: flex;
          flex-direction: row;

          .header {
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px #eeeeee;
          }
        }

        .price__box {
          margin-top: 16px;
        }

        .type__box {
          display: flex;
          margin-top: 12px;

          .area__box {
            margin-right: 8px;
            display: flex;
            align-items: center;
          }

          .sub-area__box {
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px $--gray-200;
          }

          .trade__box {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $--main-500;
          }
        }
      }

      .sub__box {
        display: none;
      }
    }

    .mypage-nav__container {
      display: flex;
      width: 100%;
      border-bottom: solid 1px #eeeeee;
      margin-top: 24px;
      margin-bottom: 20px;
      padding: 0px 16px;
      box-sizing: border-box;
      overflow: hidden;
      ul {
        width: 100%;
        // display: flex;
        display: grid;
        grid-template-rows: repeat(3, 40px);
        grid-template-columns: repeat(3, 1fr);
        li {
          height: 100%;
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px $--gray-200;
          &:first-of-type {
            border-right: 0;
          }
          &:nth-of-type(3),
          &:nth-of-type(6) {
            border-left: 0;
          }
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            border-top: 0;
          }
          &:nth-of-type(5) {
            border-left: 0;
          }
          &.on {
            border: solid 1px $--main-500;
            background-color: $--main-100;
            z-index: 10;
            span {
              color: $--main-500;
              font-weight: 600;
            }
          }
          span {
            font-size: 16px;
            color: $--grey-500;
            padding: 8px;
            white-space: nowrap;
          }
        }
      }
    }

    .purchase-list__container {
      .serach__box {
        margin-top: 0px;
        padding: 0px 16px;
        box-sizing: border-box;

        .form-text-input {
          width: 100% !important;
        }

        .basic-button {
          min-width: 80px;
        }
      }

      .list__box {
        .header {
          display: none;
        }

        .data {
          li {
            flex-direction: column;
            padding-bottom: 24px;
            border-bottom: solid 8px #eeeeee;

            .date {
              max-width: 100%;
              justify-content: space-between;
              margin-bottom: 16px;

              span {
                font-weight: bold;
                font-size: 15px;
              }

              em {
                display: flex;
                font-size: 15px;
                color: #aaaaaa;
              }
            }

            .title {
              display: grid;
              grid-template-columns: 110px 1fr;
              .img__box {
                margin-right: 16px;
                width: 100px;
                height: 100px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .title__box {
                padding-left: 8px;
                .name {
                  margin-top: 0px;
                }
              }

              .total-price {
                display: flex;
                margin-top: 8px;

                span {
                  font-weight: normal;
                  font-size: 18px;
                }
              }
            }

            .order {
              display: none;
            }

            .button {
              width: 100%;
              max-width: 100%;
              box-sizing: border-box;
              padding: 0px 16px;
              flex-direction: row;
              justify-content: flex-end;
              margin-top: 16px;

              .detail-button {
                width: 88px;
                margin-right: 16px;
                height: 44px;
              }

              .cert-button {
                width: 124px;
                height: 44px;
              }
            }
          }
        }
      }
    }

    .purchase-detail__container {
      margin-top: 0px;
      padding-top: 24px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);

      .order__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          .header {
            display: none;
          }

          .data {
            padding: 24px 0px;
            flex-direction: column;

            li {
              &:first-of-type {
                display: none;
              }

              &:nth-child(2) {
                flex: 1;
                max-width: 100%;
                align-items: flex-start;

                .img__box {
                  width: 104px;
                  margin-right: 12px;
                  height: fit-content;
                }

                .title__box {
                  margin-top: 4px;

                  .price {
                    span {
                      font-size: 18px;
                    }
                  }
                }
              }

              &:last-child {
                margin-top: 12px;
                flex: 1;
                max-width: 100%;

                .cert-button {
                  width: 100%;
                  height: 44px;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .m-order-number__box {
          display: flex;
          height: 58px;
          border-bottom: solid 1px #eeeeee;

          .header {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            height: 100%;
            padding: 0px 16px;
            box-sizing: border-box;
            white-space: nowrap;
          }

          .value {
            border-top: solid 1px #777777;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 16px;
            font-size: 15px;
            font-weight: bold;
            color: #5d3be5;
          }
        }
      }

      .payment__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          flex-direction: row;

          .header {
            flex-direction: column;
            height: auto;
            padding: 0px;
            border-bottom: none;

            li {
              padding: 0px 16px;
              box-sizing: border-box;
              height: 60px;
              flex: none;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              border-bottom: solid 1px #eeeeee;

              &:last-child {
                display: none;
              }

              &.VBANK {
                height: 130px;
              }
            }
          }

          .data {
            flex-direction: column;
            height: auto;
            width: 100%;
            border-top: solid 1px #000;
            border-bottom: none;
            padding: 0px;

            li {
              padding-left: 16px;
              width: 100%;
              max-width: 100%;
              height: 60px;
              flex: none;
              justify-content: flex-start;
              align-items: center;
              border-bottom: solid 1px #eeeeee;
              font-size: 16px;

              &:first-of-type {
                width: 100%;
                max-width: 100%;
                font-size: 16px;
              }

              &:last-child {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
                display: none;
              }

              &.VBANK {
                height: 130px;
              }
            }
          }
        }
      }

      .buyer__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          flex-direction: row;

          .header {
            flex-direction: column;
            height: auto;
            padding: 0px;
            border-bottom: none;

            li {
              padding: 0px 16px;
              box-sizing: border-box;
              height: 60px;
              flex: none;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              border-bottom: solid 1px #eeeeee;
            }
          }

          .data {
            flex-direction: column;
            height: auto;
            width: 100%;
            border-top: solid 1px #000;
            border-bottom: none;
            padding: 0px;

            li {
              padding-left: 16px;
              width: 100%;
              max-width: 100%;
              height: 60px;
              flex: none;
              justify-content: flex-start;
              align-items: center;
              border-bottom: solid 1px #eeeeee;
              font-size: 16px;

              &:first-of-type {
                width: 100%;
                max-width: 100%;
                font-size: 16px;
              }

              &:last-child {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
              }
            }
          }
        }
      }

      .m-receipt__box {
        display: flex;

        border-bottom: solid 1px #eeeeee;
        justify-content: center;
        padding: 16px 16px 24px 16px;
        box-sizing: border-box;

        .button {
          cursor: pointer;
          width: 100%;
          border-radius: 8px;
          border: solid 1px #d8d8d8;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
        }
      }

      .button__box {
        padding: 0px 16px;
        box-sizing: border-box;

        .button {
          width: 100%;
        }
      }
    }

    .forward-purchase-list__container {
      .serach__box {
        margin-top: 0px;
        justify-content: center;
        padding: 0px 16px;
        box-sizing: border-box;
        border-bottom: none;

        .form-text-input {
          width: 100% !important;
        }

        .basic-button {
          min-width: 80px;
        }
      }

      .filter__box {
        padding: 0px 16px;
        box-sizing: border-box;
        padding-top: 12px;
        padding-bottom: 24px;
      }

      .list__box {
        ul {
          li {
            flex-direction: column;
            border-bottom: solid 10px #eeeeee;

            .info__box {
              padding: 0px 16px;
              box-sizing: border-box;

              .status__box {
                margin-top: 36px;

                // span {
                //   font-size: 15px;
                // }
                // .date {
                //   font-size: 15px;
                // }
                // .order {
                //   span {
                //     font-size: 15px;
                //   }
                // }
                .date {
                  margin-left: 4px;
                }
              }

              .caption__box {
                .img__box {
                  min-width: 104px;
                  max-width: 104px;
                  margin-right: 16px;
                  max-height: unset;
                  min-height: unset;
                  height: fit-content;
                }

                .title__box {
                  .price {
                    span {
                      font-size: 12px;
                    }
                  }

                  .total-price {
                    margin-top: 4px;
                    display: flex;
                  }
                }

                .price__box {
                  display: none;
                }
              }
            }

            .option__box {
              margin-top: 20px;
              padding-top: 24px;
              width: 100%;
              padding: 24px 0px 24px 0px;
              flex-direction: column;

              .forward-status {
                margin-top: 0px;
                margin-left: 0px;
                flex-direction: column;
                border-right: none;
                padding-right: 0px;
                border-bottom: solid 1px #eeeeee;
                padding-bottom: 24px;
                padding-left: 16px;
                padding-right: 16px;

                .ready {
                  margin-bottom: 8px;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-right: 0px;
                }

                .complete {
                  flex-direction: row;
                  justify-content: space-between;
                }
              }

              .button__box {
                width: 100%;
                padding: 0px 16px;
                padding-top: 16px;
                box-sizing: border-box;

                .button {
                  width: 100%;
                  height: 44px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }

    .forward-purchase-detail__container {
      margin-top: 0px;
      padding-top: 24px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);

      .m-order-number__box {
        display: flex;
        height: 58px;
        border-bottom: solid 1px #eeeeee;

        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-weight: bold;
          height: 100%;
          padding: 0px 16px;
          box-sizing: border-box;
          white-space: nowrap;
        }

        .value {
          border-top: solid 1px #777777;
          display: flex;
          align-items: center;
          width: 100%;
          padding-left: 16px;
          font-size: 15px;
          font-weight: bold;
          color: #5d3be5;
        }
      }

      .order__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          .header {
            display: none;
          }

          .data {
            padding: 24px 0px;
            flex-direction: column;

            li {
              &:first-of-type {
                display: none;
              }

              &:nth-child(2) {
                flex: 1;
                max-width: 100%;
                align-items: flex-start;

                .img__box {
                  width: 104px;
                  margin-right: 12px;
                  height: fit-content;
                }

                .title__box {
                  margin-top: 4px;

                  .price {
                    span {
                      font-size: 18px;
                    }
                  }
                }
              }

              &:last-child {
                margin-top: 12px;
                flex: 1;
                max-width: 100%;

                .cert-button {
                  width: 100%;
                  height: 44px;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .m-order-number__box {
          display: flex;
          height: 58px;
          border-bottom: solid 1px #eeeeee;

          .header {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            height: 100%;
            padding: 0px 16px;
            box-sizing: border-box;
            white-space: nowrap;
          }

          .value {
            border-top: solid 1px #777777;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 16px;
            font-size: 15px;
            font-weight: bold;
            color: #5d3be5;
          }
        }
      }

      .payment__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          flex-direction: row;

          .header {
            flex-direction: column;
            height: auto;
            padding: 0px;
            border-bottom: none;

            li {
              padding: 0px 16px;
              box-sizing: border-box;
              height: 60px;
              flex: none;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              border-bottom: solid 1px #eeeeee;

              &:last-child {
                display: none;
              }

              &.VBANK {
                height: 130px;
              }
            }
          }

          .data {
            flex-direction: column;
            height: auto;
            width: 100%;
            border-top: solid 1px #000;
            border-bottom: none;
            padding: 0px;

            li {
              padding-left: 16px;
              width: 100%;
              max-width: 100%;
              height: 60px;
              flex: none;
              justify-content: flex-start;
              align-items: center;
              border-bottom: solid 1px #eeeeee;
              font-size: 16px;

              &:first-of-type {
                width: 100%;
                max-width: 100%;
                font-size: 16px;
              }

              &:last-child {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
                display: none;
              }

              &.VBANK {
                height: 130px;
              }
            }
          }
        }
      }

      .buyer__box {
        & > header {
          padding: 0px 16px;
          box-sizing: border-box;
        }

        .table__box {
          flex-direction: row;

          .header {
            flex-direction: column;
            height: auto;
            padding: 0px;
            border-bottom: none;

            li {
              padding: 0px 16px;
              box-sizing: border-box;
              height: 60px;
              flex: none;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              border-bottom: solid 1px #eeeeee;
            }
          }

          .data {
            flex-direction: column;
            height: auto;
            width: 100%;
            border-top: solid 1px #000;
            border-bottom: none;
            padding: 0px;

            li {
              padding-left: 16px;
              width: 100%;
              max-width: 100%;
              height: 60px;
              flex: none;
              justify-content: flex-start;
              align-items: center;
              border-bottom: solid 1px #eeeeee;
              font-size: 16px;

              &:first-of-type {
                width: 100%;
                max-width: 100%;
                font-size: 16px;
              }

              &:nth-child(2) {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
              }

              &:last-child {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
              }
            }
          }
        }
      }

      .m-receipt__box {
        display: flex;

        border-bottom: solid 1px #eeeeee;
        justify-content: center;
        padding: 16px 16px 24px 16px;
        box-sizing: border-box;

        .button {
          cursor: pointer;
          width: 100%;
          border-radius: 8px;
          border: solid 1px #d8d8d8;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
        }
      }

      .calculate__box {
        & > header {
          flex-direction: column;
          align-items: flex-start;
          padding: 0px 16px;
          box-sizing: border-box;

          span {
            &.desc {
              margin-left: 0px;
              margin-top: 8px;
            }
          }
        }

        .table__box {
          display: none;
        }

        .m-table__box {
          display: flex;
          flex-direction: column;

          &.company {
            & > ul li .complete {
              header {
                min-width: 130px;
              }
            }
          }

          & > ul {
            border-bottom: solid 1px #eeeeee;

            li {
              flex-direction: column;
              border-top: solid 1px #eeeeee;
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0px;
              }

              .date {
                display: flex;
                align-items: center;
                height: 60px;

                & > header {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0px 16px;
                  box-sizing: border-box;
                  background: #f7f7f9;
                  height: 100%;
                }

                .value {
                  display: flex;
                  align-items: center;
                  padding-left: 16px;

                  .ton {
                    margin-left: 16px;
                  }

                  .button {
                  }
                }
              }

              .complete {
                display: flex;
                align-items: center;
                height: 60px;

                & > header {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0px 16px;
                  box-sizing: border-box;
                  background: #f7f7f9;
                  height: 100%;
                }

                .value {
                  display: flex;
                  align-items: center;
                  padding-left: 16px;

                  .ton {
                    margin-left: 16px;
                  }

                  .button {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    height: 38px;
                    border: solid 1px #d8d8d8;
                    font-size: 14px;
                    width: 120px;
                  }
                }
              }

              &:first-of-type {
                border-top: solid 1px #777777;
              }

              display: flex;
            }
          }
        }

        .m-account__box {
          display: flex;
          border-bottom: solid 1px #eeeeee;
          justify-content: center;
          padding: 16px 16px 24px 16px;
          box-sizing: border-box;

          .button {
            cursor: pointer;
            width: 100%;
            border-radius: 8px;
            border: solid 1px #d8d8d8;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
          }
        }
      }

      .button-list__box {
        padding: 0px 16px;
        box-sizing: border-box;

        .button {
          width: 100%;
        }
      }
    }

    .sell-list__container {
      .serach__box {
        margin-top: 0px;
        justify-content: center;
        padding: 0px 16px;
        box-sizing: border-box;
        border-bottom: none;

        .form-dropdown-input {
          width: 100% !important;
        }

        .basic-button {
          min-width: 80px;
        }
      }

      .filter__box {
        padding: 0px 16px;
        box-sizing: border-box;
        padding-top: 12px;
        padding-bottom: 24px;

        .select__box {
          width: 100%;
        }

        .form-select-box {
          width: 100% !important;
          max-width: 165px !important;
          min-width: 130px !important;
        }
      }

      .list__box {
        & > ul {
          li {
            flex-direction: column;
            border-bottom: solid 10px #eeeeee;

            .info__box {
              padding-right: 0px;
              padding: 0px 16px;
              padding-top: 36px;
              box-sizing: border-box;

              .status__box {
                .status {
                  .date {
                    margin-left: 4px;
                  }
                }

                .order {
                  .forward-type__box {
                    display: none;
                  }
                }
              }

              .caption__box {
                .img__box {
                  width: 104px;
                  min-width: none;
                  min-height: none;
                  max-width: none;
                  max-height: none;
                  margin-right: 16px;
                }

                .title__box {
                  .forward-type__box {
                    display: flex;
                    height: 24px;
                    padding: 4px 8px;
                    box-sizing: border-box;
                    border: solid 1px #ded6ff;
                    background: #faf9ff;
                    width: fit-content;
                    margin-bottom: 4px;

                    span {
                      font-size: 12px;
                      color: #5d3be5;
                    }
                  }
                }
              }
            }

            .option__box {
              width: 100%;
              margin-top: 16px;
              flex-direction: column;
              padding: 24px 0px;

              .price__box {
                padding-bottom: 24px;
                border-bottom: solid 1px #eeeeee;

                .total__box {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  padding: 0px 16px;
                  box-sizing: border-box;

                  header {
                    display: flex;
                  }
                }

                .ton__box {
                  width: 100%;

                  display: flex;
                  justify-content: flex-end;
                  padding: 0px 16px;
                  box-sizing: border-box;
                }
              }

              .button__box {
                padding: 0px 16px;
                box-sizing: border-box;
                padding-top: 16px;
                width: 100%;

                .button {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .calculate-stat__container {
      margin-top: 40px;
      flex-direction: column;
      background: #fff;
      padding: 0px;

      .current__box {
        border: none;
        padding: 16px;
        box-sizing: border-box;

        .guide__box {
          margin-top: 20px;
        }
      }

      .withdraw__box {
        padding: 16px;
        padding-top: 24px;
        box-sizing: border-box;
        background: #f7f7f7;

        .won__box {
          flex-direction: column;
          align-items: flex-start;

          .input__box {
            margin-top: 12px;
            width: 100%;
          }
        }

        .button__box {
          .account-button {
            flex: 1;
            width: auto;
          }

          .withdraw-button {
            flex: 1;
            width: auto;
          }
        }
      }
    }

    .calculate-filter__box {
      flex-direction: column;

      .date__box {
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;

        .form-select-box {
          display: none;
        }

        .form-date-picker {
          width: 100% !important;
          max-width: calc(100vw / 2 - 26px) !important;

          .input__box {
            input {
              padding: 0px 8px;
            }
          }
        }
      }

      & > .input__box {
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        margin-top: 12px;
        justify-content: space-between;

        .form-select-box {
          display: flex;
        }

        .form-dropdown-input {
          padding: 0px 12px;
          overflow: hidden;
        }

        .basic-button {
          min-width: 80px;
        }
      }
    }

    .calculate-summary__box {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 16px;
      box-sizing: border-box;

      .summary {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        span {
          font-size: 16px;
        }
      }
    }

    .mobile-purchase-button__container {
      display: flex;
      width: 100%;
      position: fixed;
      flex-direction: column;
      bottom: 0px;
      z-index: 20;

      &.open {
        .purchase__box {
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          border-top: solid 1px #dadada;

          & > header {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
            margin-top: 12px;
          }

          .list__box {
            display: flex;
            flex-direction: column;
            padding: 0px 16px;
            box-sizing: border-box;
            padding-bottom: 16px;

            .forward__box,
            .normal__box {
              display: flex;
              flex-direction: column;
              border-radius: 8px;
              border: solid 1px #dadada;
              background-color: $--gray-0;
              width: 100%;

              & > header {
                padding: 0px 20px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                height: 50px;
                align-items: center;
                border-bottom: solid 1px #dadada;
                box-sizing: border-box;

                .type {
                  display: flex;
                  align-items: center;

                  span {
                    font-weight: bold;
                    margin-right: 4px;

                    em {
                      color: $--main-500;
                    }
                  }
                }

                .amount {
                  display: flex;
                  border-radius: 16px;
                  border: solid 1px #dadada;
                  height: 32px;
                  align-items: center;
                  padding: 0px 12px;

                  em {
                    font-size: 14px;
                    color: $--main-500;
                    font-weight: bold;
                    margin-right: 4px;
                  }

                  span {
                    font-size: 14px;
                  }
                }
              }

              .action__box {
                display: flex;
                flex-direction: column;
                padding: 24px 20px;
                box-sizing: border-box;

                .ton__box {
                  display: flex;
                  box-sizing: border-box;
                  justify-content: space-between;

                  h1 {
                    font-weight: 700;
                    letter-spacing: -0.5px;
                    em {
                      margin-left: 8px;
                      font-weight: normal;
                      font-size: 16px;
                    }
                  }

                  .status__box {
                    display: flex;
                    border-radius: 4px;
                    border: solid 1px $--main-500;
                    background-color: $--gray-0;
                    height: 32px;
                    padding: 0px 10px;
                    box-sizing: border-box;
                    align-items: center;

                    &.ready {
                      border-color: #f41515;

                      span {
                        color: #f41515;
                      }
                    }

                    &.SoldOut {
                      border-color: transparent;
                      padding: 0px;
                    }

                    span {
                      font-size: 14px;
                      color: $--main-500;
                    }
                  }
                }

                .vrc-meaning {
                  padding: 20px 0 10px;
                  p {
                    font-size: 14px;
                    line-height: 1.3;
                    color: $--gray-600;
                    span {
                      font-size: inherit;
                      line-height: inherit;
                      &.key {
                        color: $--main-500;
                        font-weight: 700;
                      }
                    }
                    &::before {
                      content: '';
                      width: 3px;
                      height: 3px;
                    }
                  }
                }

                .button__box {
                  display: flex;
                  margin-top: 16px;
                  width: 100%;
                  height: 50px;
                  border-radius: 25px;
                  background-color: $--main-500;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  cursor: pointer;

                  &.disabled {
                    pointer-events: none;
                    background-color: #eeeeee;
                    color: #161616;
                  }
                }
              }
            }
          }
        }

        .button__box {
          display: none;
        }
      }

      .purchase__box {
        display: none;
      }

      .button__box {
        display: flex;
        align-items: center;
        height: 68px;
        border-top: solid 1px #dadada;
        background-color: #fff;
        padding: 0px 16px;
        box-sizing: border-box;

        .basic-button {
          width: 100%;
        }
      }
    }

    //form page
    .m-headding-text__container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 28px;
      padding-bottom: 16px;
      border-bottom: solid 1px #eeeeee;
    }

    .login-form__container {
      width: 100vw;
      max-width: 360px;
      margin-bottom: 56px;
      box-sizing: border-box;
      padding: 0px 16px;
    }

    .singup-select__container {
      margin-top: 24px;
      margin-bottom: 56px;

      .google {
        max-width: 100%;
      }

      .m-tab__box {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 16px;
        margin-bottom: 56px;

        ul {
          width: 100%;
          border-radius: 12px;
          overflow: hidden;
          display: flex;

          li {
            flex: 1;
            height: 50px;
            background-color: #f7f7f7;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;

            &.on {
              background: $--main-500;
              color: #fff;
            }
          }
        }
      }

      & > ul {
        .border-line {
          display: none;
        }

        li {
          display: none;

          & > header {
            display: none;
          }

          &.on {
            display: flex;
            max-width: 100%;
            box-sizing: border-box;
            padding: 0px 16px;
          }
        }
      }
    }

    .user-stepper__container {
      width: 100%;
      margin-top: 0px;

      .form-stepper {
        ul {
          li {
            display: none;

            span {
              font-size: 18px;
            }

            &.on {
              display: flex;
            }
          }
        }
      }
    }

    .user-form__container {
      width: 100%;

      & > header {
        box-sizing: border-box;
        padding: 0px 16px;
        padding-bottom: 12px;

        h2 {
          font-size: 18px;
        }
      }

      .terms__list {
        box-sizing: border-box;
        padding: 0px 16px;
      }

      .form__list {
        box-sizing: border-box;
        padding: 0px 16px;

        .empty__box {
          padding-left: 0px !important;
        }

        .form-file-uploader {
          &.image {
            .preview__box {
              min-width: 128px;
              min-height: 128px;
            }
          }
        }
      }

      &.signout {
        box-sizing: border-box;
        padding: 0px 16px;

        h1 {
          font-size: 24px;
          text-align: center;
        }

        h2 {
          font-size: 20px;
        }
      }
    }

    .user-email__container {
      width: 100%;
      box-sizing: border-box;

      .mail__box {
        h1 {
          font-size: 24px;
        }
      }

      .guide__box {
        box-sizing: border-box;
        padding: 0px 16px;
      }

      .info__box {
        padding: 12px 16px;
        height: auto;
      }
    }

    .button__container {
      box-sizing: border-box;
      padding: 0px 16px;

      .secondary-button {
        width: 100% !important;
      }

      .basic-button {
        width: 100% !important;
      }

      .info-button {
        width: 100% !important;
      }
    }

    .user-signout-form__container {
      margin-top: 40px;

      .grid__box {
        display: flex;
        flex-direction: column;

        .form__list {
          box-sizing: border-box;
          padding: 0px 16px;

          .guide__box {
            h2 {
              font-size: 18px;
            }
          }
        }
      }
    }

    .notice__container {
      .serach__box {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 0px 16px;
        box-sizing: border-box;
      }

      .board__box {
        margin-top: 0px;
      }
    }

    .faq__container {
      margin-top: 20px;

      .option__box {
        border: none;
        border-radius: none;

        .guide__box {
          flex-direction: column;
          padding: 0px 16px;
          box-sizing: border-box;

          .inquiry__box {
            flex: 1;
            width: 100%;
            border-radius: 12px;
            border: solid 1px #e3e3e3;
            overflow: hidden;

            .illust {
              display: none;
            }
          }

          .phone__box {
            margin-top: 16px;
            border-radius: 12px;
            border: solid 1px #e3e3e3;
            overflow: hidden;

            .illust {
              width: 148px;
              height: 124px;
              margin-right: -13px;
            }
          }
        }

        .serach__box {
          padding: 0px 16px;
          box-sizing: border-box;

          .basic-button {
            min-width: 80px;
          }
        }

        .category__box {
          padding: 0px 16px;
          box-sizing: border-box;
          margin-bottom: 0px;

          ul {
            width: 100%;

            li {
              margin-right: 16px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .contact-us-form__container {
      padding: 0px 16px;
      box-sizing: border-box;

      .form__box {
        margin-top: 24px;
        width: 100%;
        margin-right: 0px;

        .guide-phone__box {
          border-radius: 16px;
          border: solid 1px #e3e3e3;
          overflow: hidden;

          .illust {
            width: 148px;
            height: 124px;
            margin-right: 23px;
          }
        }
      }

      .map__box {
        display: none;
      }
    }
  }
}
