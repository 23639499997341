.main-layout {
  // display: flex;
  width: 100%;
  min-height: 100vh;
  // position: relative;
  // flex-direction: column;
  // align-items: center;
  background-color: $--gray-0;
  // margin-top: 88px;
  &.mt {
    margin-top: 80px;
    @media only screen and (max-width: 640px) {
      margin-top: 60px;
    }
  }
  .content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: $wrapper-min-width;
    max-width: $wrapper-min-width;
  }
  .header__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 99;
  }

  .content__box {
    width: 100%;
  }

  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin-top: 80px;
    background-color: $--gray-0;
    border-top: solid 1px $--gray-200;
  }
}

@media only screen and (max-width: 1136px) {
  .main-layout {
    // margin-top: 60px;
    .content__wrapper {
      min-width: 100%;
      max-width: 100%;
    }
    .footer__box {
      margin-top: 36px;
    }
  }
}
