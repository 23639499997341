.purchase-modal {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  & > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 60px;
    padding: 0px 14px;
    border-bottom: solid 1px #777777;
  }
  
  .project__box {
    display: flex;
    padding: 36px 20px 24px 20px;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #eee;

    .img__box {
      display: flex;
      width: 156px;
      margin-right: 18px;
      align-items: flex-start;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .caption__box {
      display: flex;
      flex: 1;
      flex-direction: column;

      .id__box {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: $--gray-400;
        }
      }

      .title__box {
        display: flex;
        margin-top: 8px;
      }

      .price__box {
        margin-top: 12px;
        display: flex;
      }

      .date__box {
        display: flex;
        align-items: center;
        margin-top: 8px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .info__box {
    display: flex;
    padding: 24px 0px;
    box-sizing: border-box;
    width: 100%;
    // background-color: $--gray-100;
    flex-direction: column;
    .amount__box {
      display: grid;
      grid-template-columns: 20% 1fr;
      width: 100%;
      align-items: center;
      padding: 0px 20px;
      box-sizing: border-box;
      @media only screen and (max-width: 1136px) {
        display: block;
        header {
          margin-bottom: 10px;
        }
      }

      .slider__box {
        display: flex;
        flex: 1;

        & > header {
          margin-right: 20px;
          display: flex;
          align-items: center;
        }

        .input__box {
          flex: 1;
          margin-right: 30px;
          display: flex;
          align-items: center;

          .desc {
            font-size: 12px;
          }

          .MuiSlider-colorPrimary {
            margin: 0px 12px;
          }

          .MuiSlider-rail {
            color: #d8d8d8;
            height: 2px;
            opacity: 1;
          }

          .MuiSlider-track {
            color: #5d3be5;
            height: 2px;
          }

          .MuiSlider-thumbColorPrimary {
            color: #5d3be5;
          }
        }
      }

      .view__box {
        display: flex;
        // width: 190px;
        width: 100%;
        justify-content: flex-end;
        padding-right: 16px;
        border-radius: 8px;
        border: solid 1px #d8d8d8;
        background: #fff;
        height: 50px;
        align-items: center;

        input {
          width: 150px;
          text-align: right;
          border: none;
          font-size: 16px;
          padding-right: 4px;
          box-sizing: border-box;
        }
      }
    }

    .amount__button__box {
      display: flex;
      justify-content: flex-end;
      margin: 10px 20px;
      & > div {
        margin: 5px;
      }
    }

    .reason__box {
      margin-top: 16px;
      // display: flex;
      // align-items: flex-start;
      // justify-content: space-between;
      display: grid;
      grid-template-columns: 20% 1fr;
      padding: 0px 20px;
      box-sizing: border-box;
      @media only screen and (max-width: 1136px) {
        display: block;
        header {
          margin-bottom: 10px;
        }
      }
      header {
        display: flex;
        align-items: flex-start;
        padding-top: 13px;
      }

      .select__box {
        .form-select-box {
          background: #fff;
        }

        .desc {
          margin-top: 8px;

          span {
            color: #777777;
            font-size: 14px;
          }
        }

        .custom__box {
          width: 100%;
          height: 50px;
          margin-top: 8px;

          input {
            border-radius: 8px;
            border: solid 1px #d8d8d8;
            width: 100%;
            height: 100%;
            padding: 0px 16px;
            box-sizing: border-box;
          }
        }
      }
    }

    .present__box {
      display: grid;
      grid-template-columns: 20% 1fr;
      // background: #eeeeee;
      padding: 16px 20px;
      box-sizing: border-box;
      // justify-content: flex-end;
      margin-top: 16px;
      @media only screen and (max-width: 1136px) {
        display: block;
        header {
          margin-bottom: 10px;
        }
      }
    }
    .wrapper {
      width: 100%;
      background-color: $--gray-100;
      padding: 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .name__box {
          input {
            padding: 0px 16px;
            border-radius: 8px;
            border: solid 1px #d8d8d8;
            background-color: #fff;
            height: 50px;
            width: 190px;
            box-sizing: border-box;
          }
        }
  
        .email__box {
          margin-top: 8px;
          input {
            padding: 0px 16px;
            border-radius: 8px;
            border: solid 1px #d8d8d8;
            background-color: #fff;
            height: 50px;
            width: 350px;
            box-sizing: border-box;
          }
        }
      }
      .desc__box {
        margin-top: 8px;
        text-align: right;
        span {
          color: #777777;
          display: block;
          font-size: 14px;
          line-height: 1.43;
        }
      }
    }

    .price__box {
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 20px;
      box-sizing: border-box;

      header {
        display: flex;
        align-items: center;
        height: 38px;
      }

      .value__box {
        display: flex;
        align-items: center;

        .certification__box {
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          margin-left: 16px;
          cursor: pointer;
          border-radius: 8px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
          display: flex;
          padding: 0px 16px;
          align-items: center;
          justify-content: center;
          height: 38px;
          box-sizing: border-box;

          span {
            font-size: 14px;
            color: #555b67;
          }
        }
      }
    }
  }

  .caution__box {
    display: flex;
    width: 100%;

    .text__box {
      display: flex;
      flex-direction: column;

      span,
      em {
        line-height: 1.43;
        font-size: 14px;
        margin-bottom: 8px;

        &.underline {
          text-decoration: underline;
        }
      }

      em {
        color: #777777;
      }
    }

    .MuiAccordion-gutters {
      width: 100%;
      box-shadow: none;
      border-bottom: solid 1px #f7f7f9;
    }
  }

  .forward-caution__box {
    display: flex;
    padding: 12px 20px;
    box-sizing: border-box;
    flex-direction: column;

    span {
      font-size: 14px;
      line-height: 1.43;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 12px;
    }
  }

  .terms__box {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0px 20px;

    .form-check-box {
      margin-bottom: 18px;

      .label__box {
        span {
          color: #777777;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .button__box {
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    width: 100%;

    .button__item {
      width: 160px;
      display: flex;
      margin-right: 16px;

      .tooltip__box {
        display: flex;
        width: 100%;
        position: relative;

        &:hover {
          .tooltip__item {
            display: block;
          }
        }

        .tooltip__item {
          display: none;
          position: absolute;
          top: -85px;
          width: 520px;
          left: -273px;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.43);
          padding: 12px 16px;
          box-sizing: border-box;
          background: #fff;

          span {
            font-size: 14px;
            color: #777;
          }

          // bottom: -12px;
          //   left: 65%;
          // &::after {
          //   content: '';
          //   position: absolute;
          //   border: 1px solid rgba(51, 51, 51, 0.19);
          //   border-color: white transparent;
          //   border-width: 12px 13px 0 13px;
          //   bottom: -12px;
          //   left: 65%;
          // }
          // &::before {
          //   content: '';
          //   position: absolute;
          //   border: 1px solid #333;
          //   border-color: #999 transparent;
          //   border-width: 11px 13px 0 13px;
          //   bottom: -13px;
          //   left: 65%;
          //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.43);
          // }
          &::after {
            content: '';
            position: absolute;
            box-shadow: rgba(0, 0, 0, 0.21) 2px 2px 1px;
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            bottom: -9px;
            left: 66%;
            border-width: 10px;
            border-style: solid;
            border-color: transparent #fff #fff transparent;
          }
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.account-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  & > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 60px;
    padding: 0px 14px;
    border-bottom: solid 1px #777777;
  }

  .form__list {
    display: flex;
    flex-direction: column;
    padding: 0px 110px;
    box-sizing: border-box;
    width: 100%;

    & > article {
      margin-top: 32px;
    }
  }

  .button__box {
    margin-top: 76px;
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
    width: 100%;

    .button__item {
      width: 160px;
      display: flex;
      margin-right: 16px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.certification-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    height: 64px;
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .number__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;

    span {
      font-size: 14px;
      color: #000000;
    }
  }

  .img__box {
    margin-top: 8px;
    display: flex;
    margin-bottom: 28px;
  }

  .title__box {
    margin-bottom: 8px;

    h1 {
      font-weight: normal;
    }
  }

  .amount__box {
    margin-bottom: 12px;

    h2 {
      color: $--main-500;
      font-weight: normal;
    }
  }

  .name__box {
    margin-bottom: 28px;

    h2 {
      font-weight: normal;
    }
  }

  .info__box {
    display: flex;
    border-top: solid 1px #161616;
    width: 100%;

    & > ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        height: 48px;
        border-bottom: solid 1px #eeeeee;
        box-sizing: border-box;
        width: 100%;

        &.project {
          min-height: 74px;
        }

        header {
          display: flex;
          min-width: 100px;
          padding-right: 16px;
          padding-left: 20px;

          span {
            font-weight: bold;
          }
        }

        .data {
          display: flex;
          flex-direction: column;

          span {
            line-height: 1.5;

            &.id {
              font-size: 14px;
              color: #777777;
            }
          }
        }
      }
    }
  }

  .desc__box {
    display: flex;
    margin-top: 36px;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      font-size: 14px;
      line-height: 1.43;
    }
  }

  .organizer__box {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .url__box {
    margin-top: 36px;
    margin-bottom: 36px;

    span {
      font-size: 14px;
      color: #777777;
    }
  }
}

.contact-privacy__modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 640px;
  overflow: hidden;

  & > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px 30px 20px;
    box-sizing: border-box;
  }

  .title__box {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 12px;

    span {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }

    border-bottom: solid 1px $--grey-500;
  }

  .content__box {
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;

    h1 {
      margin: 8px 0px;
      font-size: 18px;
    }

    strong {
      margin: 8px 0px;
      font-size: 16px;
    }

    p {
      margin: 8px 0px;
      white-space: pre-line;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $--grey-500;
    }

    li {
      font-size: 14px;
      color: $--grey-500;
    }

    table {
      border: solid 1px #d1d1d1;
      margin-bottom: 12px;
      // table-layout: fixed;
      border-collapse: collapse;
      width: 100%;

      p {
        word-break: keep-all;
      }

      thead {
        background: #dbdbdb;

        th {
          text-align: center;
        }
      }

      tbody {
        td {
          padding: 0px 8px;
          border-right: solid 1px #d1d1d1;

          &:last-child {
            border-right: none;
          }
          white-space: pre-wrap;
          &.content {
            font-size: 14px;
            padding: 10px;
          }
        }
      }
    }
  }
}

.methodology-history__modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 960px;
  overflow-y: auto;
  padding-bottom: 40px;

  & > header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 64px;
    min-height: 64px;
    padding: 0px 20px 0px 40px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  }

  .table__box {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 40px;
    box-sizing: border-box;
  }
}

.project-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;

        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }

        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;

    b {
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;

      article:nth-child(2n) {
        border-right: none;
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.project-document__modal {
  display: flex;
  max-height: 880px;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;

  .header__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    min-height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    position: fixed;
    background: #fff;
    z-index: 10;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    span {
      margin-left: 20px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .step-left-button__box {
    position: absolute;
    left: -65px;
    top: 50%;
  }

  .step-right-button__box {
    position: absolute;
    right: -65px;
    top: 50%;
  }

  .document__box {
    display: flex;
    flex-direction: column;

    width: 1136px;
    margin-top: 64px;
  }
}

.modal-reduction-select {
  display: flex;
  flex-direction: column;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .project__box {
    margin-top: 40px;
    padding: 0px 40px;
    box-sizing: border-box;

    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .button__box {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
}

.reduction-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;

        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }

        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;

    b {
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;

      article:nth-child(2n) {
        border-right: none;
      }

      .monitor__box {
        display: flex;
        overflow: hidden;

        & > article {
          &:last-child {
            border-right: solid 1px #e3e3e3;
          }
        }
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.credit-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;

        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }

        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;

    b {
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;

      &.one {
        grid-template-columns: 1fr 1fr;
      }

      article:nth-child(2n) {
        border-right: none;
      }

      .monitor__box {
        display: flex;
        overflow: hidden;

        & > article {
          &:last-child {
            border-right: solid 1px #e3e3e3;
          }
        }
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.modal-credit-assignee {
  display: flex;
  flex-direction: column;
  max-height: 900px;
  overflow: auto;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    min-height: 65px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .searh__box {
    display: flex;
    margin-top: 40px;
    align-items: center;
    padding: 0px 40px;
    box-sizing: border-box;

    input {
      width: 100%;
      height: 50px;
      padding: 0px;
      margin: 0px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
      border: solid 1px $--gray-300;
      border-radius: 25px;
      padding: 0px 16px;

      &::placeholder {
        color: $--gray-400;
      }
    }
  }

  .table__box {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & > header {
      display: flex;
      padding: 0px 40px;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .header__list {
      display: flex;
      padding: 0px 40px;
      box-sizing: border-box;
      margin-top: 16px;
      width: 100%;
      height: 58px;

      li {
        border-top: solid 1px #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #f6f6f6;
        border-right: solid 1px #e3e3e3;

        &:last-child {
          border-right: none;
        }

        span {
          font-size: 14px;
          font-weight: bold;
        }

        &:first-of-type {
          max-width: 50px;
        }

        &:nth-child(2) {
          max-width: 240px;
        }
      }
    }

    .value__list {
      display: flex;
      padding: 0px 40px;
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      cursor: pointer;

      &:hover {
        li {
          background: #f6f3ff;
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #fff;
        border-right: solid 1px #e3e3e3;
        border-bottom: solid 1px #e3e3e3;
        font-size: 14px;

        &:last-child {
          border-right: none;
        }

        &:first-of-type {
          max-width: 50px;
        }

        &:nth-child(2) {
          max-width: 240px;
        }

        p {
          line-height: 1.5;
        }
      }
    }

    .empty__list {
      display: flex;
      justify-content: center;
      padding: 0px 40px;
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      align-items: center;
      font-size: 14px;
      color: #777;
    }
  }

  .button__box {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1136px) {
  .contact-privacy__modal {
    max-height: 100vh;

    & > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
      height: 64px;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
      min-height: 64px;
    }

    .title__box {
      margin-top: 24px;
    }
  }

  .certification-modal {
    & > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
      height: 64px;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
    }

    .title__box {
      text-align: center;
    }

    .desc__box {
      padding: 0px 16px;
      box-sizing: border-box;
    }
  }

  .purchase-modal {
    overflow-y: auto;

    & > header {
      min-height: 64px;
    }

    .project__box {
      .img__box {
        width: 104px;
      }
    }

    .info__box {
      .amount__box {
        .slider__box {
          .input__box {
            margin-right: 20px;
          }
        }

        .view__box {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      .reason__box {
        & > header {
          // display: none;
        }

        .select__box {
          width: 100%;

          .form-select-box {
            margin-right: 0px;
            width: 100% !important;
          }
        }
      }

      .present__box {
        justify-content: flex-start;

        .wrapper {
          width: 100%;

          .name__box {
            .label__box {
              white-space: nowrap;
            }

            input {
              width: 100%;
              margin-left: 45px;
            }
          }

          .email__box {
            input {
              width: 100%;
            }
          }
        }
      }

      .price__box {
        .value__box {
          &.mobile {
            flex-direction: column;
            align-items: flex-end;

            & > span {
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          .certification__box {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
