.table-inquiry {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px $--gray-600;
    box-sizing: border-box;
    tr {
      th {
        height: 48px;
        box-sizing: border-box;
        font-size: 16px;
        color: $--gray-900;
        border-bottom: solid 1px $--gray-200;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #f6f3ff;
      }
      td {
        height: 48px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--grey-500;
        border-bottom: solid 1px $--gray-200;

        &.Complete {
          color: $--main-500;
        }
        &:nth-child(1) {
        }
        &:nth-child(2) {
          color: $--main-500;
        }
        &:nth-child(3) {
          color: $--gray-600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 600px;
        }
        &:nth-child(4) {
        }
        &:nth-child(5) {
        }
      }
    }
  }
}

.table-paging {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  align-items: center;
  .page__box {
    display: flex;

    .MuiPagination-root {
      height: 50px;

      .MuiPagination-ul {
        height: 100%;
        li {
          &:first-of-type {
            button {
              width: 76px;
              border-left: solid 1px $--gray-200;
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
              padding-left: 8px;
            }
          }
          &:last-child {
            button {
              width: 76px;
              border-right: solid 1px $--gray-200;
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
              padding-right: 8px;
            }
          }

          button {
            opacity: 1 !important;
            color: $--grey-500;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-top: solid 1px $--gray-200;
            border-bottom: solid 1px $--gray-200;
            border-right: solid 1px $--gray-200;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
            border-radius: 0px;
            font-size: 14px;
            &.Mui-selected {
              background-color: $--main-100;
              color: $--main-500;
            }
            &.Mui-disabled {
              .prev,
              .next {
                opacity: 0.38;
              }
            }
          }
          .MuiPaginationItem-ellipsis {
            color: $--grey-500;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-top: solid 1px $--gray-200;
            border-bottom: solid 1px $--gray-200;
            border-right: solid 1px $--gray-200;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
            border-radius: 0px;
            line-height: 50px;
          }
        }
      }
    }
  }
  .button__box {
    display: flex;
    position: absolute;
    right: 0px;
  }
}

.table-methodology {
  display: flex;
  width: 100%;
  & > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: solid 1px $--gray-200;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      .img__box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 270px;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 210px;
        }
      }

      .caption__box {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        .title {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          h2 {
            color: $--gray-600;
          }

          .version {
            margin-left: 16px;
            display: flex;
            align-items: center;
            span {
              white-space: nowrap;
              display: flex;
              align-items: center;
              &.new {
                font-weight: bold;
                color: $--main-500;
                &::after {
                  display: flex;
                  content: '';
                  height: 14px;
                  width: 1px;
                  background: #d8d8d8;
                  margin-left: 9px;
                  margin-right: 16px;
                }
              }
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #777;
            }
          }
        }
        .intro {
          margin-top: 16px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;

          p {
            font-size: 16px;
            line-height: 1.5;
            color: $--gray-600;
          }
        }
        .attribute {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }

          & > header {
            min-width: 160px;
            span {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--grey-500;
            }
          }
          .value {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--gray-600;
          }
        }
      }

      .button__box {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 40px;
        right: 0px;
      }
    }
  }
}

.table-methodology-history {
  display: flex;
  width: 100%;

  & > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > li {
      width: 100%;
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 40px;
      box-sizing: border-box;

      & > header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
      }

      .attribute__list {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > li {
          width: 100%;
          display: flex;
          align-items: center;
          min-height: 50px;
          border-bottom: solid 1px $board-border-color;
          box-sizing: border-box;
          &:first-of-type {
            border-top: solid 1px $--gray-600;
          }
          & > header {
            height: 100%;
            display: flex;
            align-items: flex-start;
            min-width: 180px;
            background: $board-bg-color;
            padding: 20px;
            box-sizing: border-box;
            border-right: solid 1px $board-border-color;
            span {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $--gray-600;
            }
          }
          .value {
            box-sizing: border-box;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 20px;
            span {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $--gray-600;
            }

            p {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              line-height: 1.5;
              color: $--gray-600;
            }
          }
        }
      }
    }
  }
}

.table-project {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px $--gray-900;
    box-sizing: border-box;
    tr {
      th {
        font-weight: bold;
        height: 78px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 0px;
        color: $--gray-900;
        background: #f6f6f6;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #f6f3ff;
      }
      td {
        padding: 0px;
        height: 104px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--gray-900;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }

        .title-2line {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          justify-content: center;
          p {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .status__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: $--gray-900;

            &.date {
              font-size: 12px;
              color: #777;
            }
            &.active {
              color: #dd3939;
            }
          }
          em {
            cursor: pointer;
            font-size: 14px;
            color: #4591cf;
            text-decoration: underline;
          }
        }

        .button__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            width: fit-content;
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        button {
          min-width: 56px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          padding: 0px 4px;
          border-radius: 8px;
          border: solid 1px $--gray-900;
          font-size: 12px;
          color: $--gray-900;
          background: #fff;

          &.main {
            color: $--main-500;
            border: solid 1px #b19ff9;
            background-color: #f6f3ff;
          }
        }
      }
    }
  }
}

.table-project-history {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px $--gray-900;
    box-sizing: border-box;
    tr {
      th {
        font-weight: bold;
        height: 58px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 0px;
        color: $--gray-900;
        background: #f6f6f6;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #f6f3ff;
      }
      td {
        padding: 0px;
        height: 70px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--gray-900;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

.table-marketplace {
  display: flex;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      display: flex;
      width: 100%;
      padding: 32px;
      height: 324px;
      box-sizing: border-box;
      border-radius: 16px;
      border: solid 1px $--gray-200;
      margin-bottom: 32px;
      position: relative;
      &:last-child {
        margin-bottom: 0px;
      }
      cursor: pointer;
      &:hover {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
        .button__box {
          .button {
            background-color: $--main-500;
            span {
              color: #fff;
            }
          }
        }
      }
      .img__box {
        display: flex;
        min-width: 338px;
        max-width: 338px;
        height: 260px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .complte__box {
          position: absolute;
          display: flex;
          right: 12px;
          bottom: 12px;
          width: 97px;
          height: 32px;
          background: #fff;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            margin-left: 4px;
            color: $--main-500;
          }
        }
      }
      .caption__box {
        margin-left: 28px;
        display: flex;
        flex: 1;
        overflow: hidden;
        flex-direction: column;

        .trade__box {
          display: flex;
          .area__box {
            margin-right: 8px;
          }
          .sub-area__box {
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px $--gray-200;
          }
          .trade__box {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $--main-500;
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px $--gray-200;
          }
          .id__box {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $--gray-400;
          }
        }
        .title__box {
          margin-top: 16px;
          h2 {
            font-weight: normal;
          }
        }
        .intro__box {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          margin-bottom: 24px;
          p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
          }
        }
        .area__box {
          display: flex;
          align-items: center;

          .header {
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px #eeeeee;
            span {
              color: #aaaaaa;
            }
          }
          .arrow {
            font-size: 14px;
            margin-left: 8px;
            margin-right: 8px;
          }
        }
        .price__box {
          display: flex;
          flex: 1;
          align-items: flex-end;
          h1 {
            font-weight: normal;
            em {
              margin-left: 8px;
              font-weight: normal;
              font-size: 16px;
            }
          }
        }
        .button__box {
          display: flex;
          position: absolute;
          right: 32px;
          bottom: 32px;
          .button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            border: solid 1px $--gray-300;
            width: 160px;
            height: 50px;
          }
        }
      }
    }
  }
}

.table-calculate {
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  border-top: solid 1px #eeeeee;
  .empty {
    width: 100%;
    padding: 24px 0px;
    border-bottom: solid 1px #eeeeee;
  }
  & > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      width: 100%;
      display: flex;
      padding: 24px 0px;
      box-sizing: border-box;
      border-bottom: solid 1px #eeeeee;

      .date__box {
        width: 80px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
      }
      .title__box {
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
        flex: 1;
        .forward-type__box {
          margin-bottom: 8px;
          height: 24px;
          width: 60px;
          border: solid 1px #ded6ff;
          background: #faf9ff;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            color: #5d3be5;
            font-size: 12px;
          }
        }
        .project-title {
          font-size: 16px;
          margin-bottom: 4px;
        }
        .project-id {
          font-size: 16px;
          color: #aaaaaa;
        }
      }

      .result__box {
        width: 300px;
        padding: 0px 16px;
        display: flex;

        .credit__box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          & > header {
            color: #aaaaaa;
            margin-bottom: 8px;
          }
        }
        .price__box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          & > header {
            color: #aaaaaa;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.table-withdraw {
  display: flex;
  margin-top: 24px;
  width: 100%;
  border-top: solid 1px #eeeeee;
  flex-direction: column;
  .empty {
    width: 100%;
    padding: 24px 0px;
    border-bottom: solid 1px #eeeeee;
  }

  .header {
    display: flex;
    height: 50px;
    box-sizing: border-box;
    background: #f7f7f9;
    border-top: solid 1px #000;
    border-bottom: solid 1px #eeeeee;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    box-sizing: border-box;

    li {
      display: flex;
      justify-content: center;
      flex: 1;
      font-size: 14px;
      &:nth-child(1),
      &:nth-child(3) {
        max-width: 160px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: 210px;
      }
    }
  }
  .data {
    display: flex;
    height: 56px;
    box-sizing: border-box;
    background: #fff;
    border-bottom: solid 1px #eeeeee;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    box-sizing: border-box;

    li {
      display: flex;
      justify-content: center;
      flex: 1;
      font-size: 14px;
      &:nth-child(1),
      &:nth-child(3) {
        max-width: 160px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        max-width: 210px;
      }
    }
  }
}

.table-m-withdraw {
  display: flex;
  width: 100%;
  border-top: solid 1px #eeeeee;
  margin-top: 24px;
  .empty {
    display: flex;
    width: 100%;
    padding: 24px 0px;
    padding-left: 16px;
    border-bottom: solid 1px #eeeeee;
  }
  flex-direction: column;
  .item {
    display: flex;
    border-bottom: solid 10px #eeeeee;
    flex-direction: column;
    li {
      display: flex;
      align-items: center;
      min-height: 48px;
      & > header {
        min-width: 86px;
      }
      padding: 12px 16px;
      border-bottom: solid 1px #eeeeee;

      p {
        line-height: 1.43;
      }
      &:last-child {
        border-color: #cccccc;
      }
    }
  }
}

@media only screen and (max-width: 1136px) {
  .table-marketplace {
    ul {
      box-sizing: border-box;
      padding: 0px 16px;
      align-items: center;
      li {
        max-width: $mobile-content-max-width;
        flex-direction: column;
        height: auto;
        padding: 0px;
        .img__box {
          min-width: 100%;
          max-width: 100%;
          height: 210px;
        }
        .caption__box {
          padding: 20px;
          margin-left: 0px;
          box-sizing: border-box;

          .intro__box {
            margin-bottom: 16px;
          }
          .price__box {
            margin-top: 20px;
            h1 {
              font-size: 18px;
            }
          }
          .button__box {
            position: relative;
            right: auto;
            bottom: auto;
            width: 100%;
            margin-top: 20px;
            .button {
              width: 100%;
              background: $--main-500;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .table-calculate {
    .empty {
      padding-left: 16px;
    }
    & > ul {
      li {
        flex-direction: column;
        border-bottom: solid 10px #eeeeee;
        .title__box {
          margin-top: 12px;

          .project-id {
            padding-bottom: 12px;
            border-bottom: solid 1px #eeeeee;
          }
        }
        .result__box {
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: 24px;
          .credit__box {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }
          .price__box {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
