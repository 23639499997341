// $wrapper-min-width: 1136px;
// $wrapper-max-width: 1136px;
$wrapper-min-width: 1280px;
$wrapper-max-width: 1280px;

$header-height: 104px;
$child-header-height: 60px;
$footer-height: 136px;

$mobile-content-max-width: 360px;
