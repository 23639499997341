.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .menu__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: $header-height;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 11;

    .content__wrapper {
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
    }
  }

  .sub-nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .sub-menu__container {
    position: absolute;
    top: 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    height: 0px;
    transition: all 0.3s linear;
    opacity: 0;
    background-color: #fff;
    &.open {
      display: flex;
      height: $child-header-height;
      opacity: 1;
      z-index: 10;
    }
    .content__wrapper {
      align-items: flex-end;
      height: 100%;
      justify-content: center;
    }

    .sub-nav__list {
      display: flex;
      height: 100%;
      li {
        height: 100%;
        margin-right: 40px;
        display: flex;
        align-items: center;
        border-bottom: solid 4px transparent;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          & > span {
            font-weight: bold;
          }
        }

        &:hover {
          border-color: $--gray-900;
          span {
            font-weight: bold;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
    }
  }

  .left__container {
    display: flex;
    height: 100%;
    align-items: center;
    .logo__box {
      display: flex;
      margin-right: 87px;
      // .ico {
      //   width: 62px;
      //   height: 24px;
      // }
    }
    ul {
      margin-right: 50px;
    }
    .nav__box {
      display: flex;
      height: 100%;
      & > ul {
        display: flex;
        height: 100%;
        & > li {
          margin-right: 30px;
          display: flex;
          position: relative;
          align-items: center;
          height: 100%;
          border-bottom: solid 4px transparent;
          box-sizing: border-box;
          &:last-child {
            margin-right: 0px;
          }
          &.on {
            border-color: $--main-500;
            & > span {
              color: $--main-500;
            }
          }

          &.active {
            & > span {
              color: $--main-500;
            }
          }
          & > span {
            cursor: pointer;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $--gray-600;
          }
        }
      }
    }
  }

  .right__container {
    display: flex;
    height: 100%;
    align-items: center;
    ul {
      display: flex;
      height: 100%;
      li {
        display: flex;
        position: relative;
        align-items: center;
        height: 100%;
        margin-right: 33px;
        border-bottom: solid 4px transparent;
        box-sizing: border-box;

        &.on {
          border-color: $--main-500;
          & > span {
            color: $--main-500;
          }
        }

        &.active {
          & > span {
            color: $--main-500;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          span {
            color: $--main-500;
          }
        }
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-600;
        }
      }
    }
    .menu__box {
      display: none;
    }
    .menu-list__box {
      display: none;
    }
  }
}

.login-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: $wrapper-min-width;
  max-width: $wrapper-min-width;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
  z-index: 100;
  .left__container {
    display: flex;
    height: 100%;
    align-items: center;
    .logo__box {
      display: flex;
      margin-right: 90px;
    }
  }

  .right__container {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 1136px) {
  .main-header {
    min-width: 100%;
    max-width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;

    .menu__container {
      box-shadow: none;
    }
    .left__container {
      .nav__box {
        display: none;
      }
    }

    .right__container {
      & > ul {
        display: none;
      }
      .menu__box {
        display: flex;
      }
      .menu-list__box {
        &.open {
          display: flex;
          z-index: 1000;
        }
        display: none;
        position: fixed;
        right: 0px;
        top: 0px;
        max-width: 300px;
        width: 100%;
        height: 100vh;
        background: #fff;
        flex-direction: column;
        border-left: solid 1px #d8d8d8;
        overflow: auto;
        & > header {
          display: flex;
          align-items: center;
          padding: 0px 16px;
          box-sizing: border-box;
          justify-content: space-between;
          margin-top: 16px;
          z-index: inherit;
        }
        .mobile-list__box {
          margin-top: 34px;
          display: flex;
          flex-direction: column;
          padding: 0px 16px;
          z-index: inherit;

          &.user {
            height: auto;
            flex: 1;
            margin-bottom: 74px;
          }
          .mobile-list__item {
            display: flex;
            align-items: center;
            min-height: 52px;
            border-bottom: solid 1px #d8d8d8;
            z-index: inherit;

            h3 {
              font-weight: normal;
            }
          }
          .mobile-accordion__item {
            display: flex;
            align-items: center;
            min-height: 52px;
            border-bottom: solid 1px #d8d8d8;
            width: 100%;
            z-index: inherit;

            &.last {
              border-bottom: none;
            }

            h3 {
              font-weight: normal;
            }

            .mobile-child__item {
              height: 48px;
              display: flex;
              align-items: center;

              span {
                font-size: 16px;
              }
            }
            .MuiAccordion-root {
              box-shadow: none;
              width: 100%;
            }
            .MuiAccordionSummary-root.Mui-expanded {
              min-height: 52px;
            }
            .MuiAccordionSummary-root {
              padding: 0px;
            }
            .MuiAccordionDetails-root {
              padding-bottom: 0px;
              padding-top: 0px;
            }
          }
        }
        .login__box {
          margin-top: 36px;
          display: flex;
          justify-content: center;
          z-index: inherit;

          .login {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #5d3be5;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 15px;
            margin-right: 24px;
            z-index: inherit;
          }
          .signup {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #3bbae5;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 15px;
            z-index: inherit;
          }
        }
        .logout__box {
          display: flex;
          height: 74px;
          background: #eeeeee;
          align-items: center;
          padding: 0px 16px;
          position: fixed;
          bottom: 0px;
          width: 100%;
          h3 {
            font-weight: normal;
          }
        }
      }
    }
  }

  .login-header {
    min-width: 100%;
    max-width: 100%;
    justify-content: center;
    .left__container {
      align-items: flex-end;
      .logo__box {
        margin-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1136px) {
  .main-header .left__container .logo__box {
    .ico {
      width: 62px;
      height: 24px;
    }
  }
  .main-header .menu__container {
    height: 64px;
  }
}
